import React from 'react';
import {PageWrapper} from 'src/control/PageWrapper/PageWrapper';
import Typography from '@mui/material/Typography';
import {Link, useTheme} from "@mui/material";
import Error from '@mui/icons-material/Error';
import {BoxDetexian} from "../components/BoxDetexian";
import {BlurbWrapper} from "../components/BlurbWrapper";
import {AnyQuestionsOrHelp} from "./Signup/Signup";
import {linkStyleFragment} from "../control/theme";
import {pagePaths} from "../routes";

function EmailSupport(props: {label: string }) {
  // const theme = useTheme()
  return <Link
    href={`mailto:support@detexian.com?subject=Discovery%20Onboarding%20Error&body=${window.location.href}`}
    sx={linkStyleFragment}
  >
    {props.label}
  </Link>;
}

const GoTo14DayTrialSignup = (props: {children: any }) => {
  // const theme = useTheme()
  return <Link
    href={pagePaths.trialConfirm}
    sx={linkStyleFragment}
  >
    {props.children}
  </Link>;
}

// const ErrorMessage = (
//   props: {
//     mailToLabel: string,
//     errorMessage: string | null
//   }) => {
//   let result: any = props.errorMessage;
//   if (!result) {
//     // result = <>
//     // <span>
//     //   Auto-generated error message - please
//     // </span>
//     // <EmailSupport label={props.mailToLabel} />
//     // </>'
//     result = 'default error message';
//   }
//   return result;
// }

export const TrialFailure = () => {
  const theme = useTheme();
  const spacing = theme.spacing(5);
  // const [searchParams] = useSearchParams();

  return (
    <PageWrapper>
      <BoxDetexian
        width="75%"
        paddingBottom={spacing}
      >
        <Error
          sx={{
            color: theme.more.riskyRed,
            fontSize: '2.5rem',
            fontWeight: 'bold',
          }}
        />
        <Typography
          variant="h3"
          sx={{
            marginBottom: spacing,
          }}
        >
          Unsuccessful
        </Typography>
        <BlurbWrapper sx={{
          margin: 'auto',
          width: '80%',
        }}>
          <Typography
            variant="body1"
            gutterBottom
          >
            Detexian was not able to set up your 14-Day Trial. Please  &nbsp;
            <EmailSupport label="send us an email"/>
            &nbsp; or <GoTo14DayTrialSignup>retry sign up</GoTo14DayTrialSignup>.
            <br/> <br/>
            {/*Error:*/}
            {/*<br/>*/}
            {/*<ErrorMessage*/}
            {/*  mailToLabel="contact support"*/}
            {/*  errorMessage={searchParams.get('error')}*/}
            {/*/>*/}
          </Typography>
        </BlurbWrapper>
      </BoxDetexian>
      <br/>
      <br/>
      <AnyQuestionsOrHelp/>
    </PageWrapper>
   );
}
