// I'd prefer this to be in control/Routing.tsx but that causes
// "Uncaught ReferenceError: Cannot access 'open' before initialization"
// in Navigation.tsx and on app launch

export const pagePaths = {
  signup: '/',
  welcome: '/welcome',
  onboardSuccess: '/onboardsuccess',
  agree: '/agree',
  onboardError: '/onboarderror',
  dashboard: '/dashboard',
  applications: '/applications',
  recommendations: '/recommendations',
  terms: '/terms',
  privacy: '/privacy',
  trialConfirm: '/confirm-trial',
  trialSuccess: '/trial-success',
  trialFailure: '/trial-failure',
}
