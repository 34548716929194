import * as React from 'react';
import {useContext} from 'react';
import {GridColDef} from '@mui/x-data-grid';
import {Button, Typography, useTheme} from '@mui/material';
import {PageWrapper} from 'src/control/PageWrapper/PageWrapper';
import {StyledDataGrid} from "src/pages/Applications/styles";
import {QueryContext, QueryProvider} from "../../api/QueryProvider";
import {ApplicationSummary} from "../../api/QueryTypes";
import {
  actionColumnIntialValue,
  addReviewItem,
  buildEnterpriseColumns,
  buildUserConsentedColumns, userCountFieldName
} from "./ColumnBuilder";
import {DtxSpacer} from "../../components/DtxSpacer";
import {pagePaths} from "../../routes";
import {useNavigate} from "react-router-dom";
import {useClientPubId} from "../../control/hooks";
import {SpacedDivider} from "../Recommendations/styles";
import {CallToActionBlurb} from "../Recommendations/Recommendations";

const enhanceRow = (aRow: ApplicationSummary) => {
  let text = actionColumnIntialValue;
  if (aRow.file_access) text = addReviewItem(text, 'file');
  if (aRow.write_access) text = addReviewItem(text, 'write');
  if (aRow.email_access) text = addReviewItem(text, 'email');
  if (aRow.calendar_access) text = addReviewItem(text, 'calendar');
  if (text !== actionColumnIntialValue) {
    text = `Review ${text} access`;
  }
  if (aRow[userCountFieldName] === 0) {
    if (text === actionColumnIntialValue) {
      text = 'Review unused app'
    }
    else text = addReviewItem(text, 'unused app');
  }

  const result = aRow;
  result['action'] = text;
  return result;
}

// add the action column
const enhanceRows = (
  rows: ApplicationSummary[]
) => rows.map((aRow: any) => enhanceRow(aRow))

export const getAppsOfTypeEnterpriseOrUser = (
  apps: any[],
  isEnterprise: boolean
) => Array.isArray(apps) ?
  apps.filter((app) => app.is_enterprise === isEnterprise) : []

// filter and optionally add the action column
const appsToDisplay = (
  apps: ApplicationSummary[],
  showEnterprise: boolean
) => {
  // let result = apps.filter((app) => app.is_enterprise === showEnterprise)
  let result = getAppsOfTypeEnterpriseOrUser(apps, showEnterprise);
  if (!showEnterprise) {
    result = enhanceRows(result) // add action column
  }
  return result;
}

// prop name showEnterprise sounds like a filter but it
// actually chooses whether to show enterprise apps or
// user-consented apps

type props = {
  apps: ApplicationSummary[];
  columns: GridColDef[];
  hasActionColumn: boolean;
  headingAboveGrid: string;
}

const AppDataGrid = ({
  apps,
  columns,
  hasActionColumn,
  headingAboveGrid,
}: props) => {
  const [pageSize, setPageSize] = React.useState<number>(10);

  return (
    <>
      <Typography
        variant="h5"
        sx={{
          color: 'black',
          margin: '3vh',
          fontWeight: 'bold'
      }}
        // onClick={()=>navigate('/')}
      >
        {apps.length} {headingAboveGrid}
      </Typography>

      <StyledDataGrid
        rows={apps}
        getRowId={(row) => row.application_pub_id}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 20, 30]}
        pagination
        hideFooter={apps.length <= pageSize}
        disableSelectionOnClick
        autoHeight ={true}
        rowSpacingType="border"
        disableColumnMenu={true}
        initialState={{
          sorting: {
            sortModel: [{
              field: userCountFieldName,
              sort: 'desc',
            }],
          },
        }}
        sx={{
          border: 'none', // for the whole container inc paging
         }}
      />
    </>
  )
}

// const UserConsentedAppList = () => {
//   return <AppDataGrid />
// }
//
// const EnterpriseAppList = () => {
//   return <AppDataGrid showEnterprise={true} />
// }

// applistColumns: GridColDef []

function ApplicationLists() {
  const theme = useTheme();
  const queryData = useContext(QueryContext);

  let userConsentedApps = appsToDisplay(
    queryData?.application_summaries,
    false
  );

  userConsentedApps = enhanceRows(userConsentedApps);
  const enterpriseApps = appsToDisplay(
    queryData?.application_summaries,
    true
  );

  return <>
    <AppDataGrid
      apps={userConsentedApps}
      columns={buildUserConsentedColumns(theme)}
      hasActionColumn={true}
      headingAboveGrid={'Non-IT-Approved Apps'}
    />
    <br/>
    <AppDataGrid
      apps={enterpriseApps}
      columns={buildEnterpriseColumns(theme)}
      hasActionColumn={false}
      headingAboveGrid={'IT-Approved Apps'}
    />
  </>;
}

export const Applications = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const clientPubId = useClientPubId();
  return (
    <PageWrapper
      shouldShowNavigation={true}
      shouldShowToolWidget={true}
      footerMessage="Get in touch for full visibility of apps and users"
    >
      <QueryProvider>
        <ApplicationLists/>
        <SpacedDivider/>

        <CallToActionBlurb>See how you can save</CallToActionBlurb>
        <DtxSpacer space={1.5}/>
        <Button
          variant="contained"
          onClick={() => navigate(
            `${pagePaths.recommendations}/${clientPubId}`
          )}
          sx={{
            color: 'white',
            backgroundColor: theme.more.andyBlue,
          }}
        >
          Recommendations
        </Button>
      </QueryProvider>
    </PageWrapper>
   );
}



  // '& .MuiDataGrid-root, .MuiDataGrid-main, .MuiDataGrid-footerContainer': {
  //   border: 'none !important',
  // },
  //
  // '& .MuiTablePagination-root, .MuiToolbar-root' : {
  //   border: 'none !important',
  // },

