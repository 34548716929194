import TableCell from "@mui/material/TableCell";
import React from "react";
import {borderRadius, tableColumnBorderColor} from "src/control/theme";
import {styled, TableContainer} from "@mui/material";

const borderWidth = 1;
const borderLeftStyle = {
  borderLeft: borderWidth,
  borderColor: tableColumnBorderColor, //// 'grey.200',
}
const dataCellStyle = {
  // border: `1px solid ${tableRowBorderColor}`,
  ...borderLeftStyle,
  fontSize: '34px',
}
export const TableHeaderCellDtx = (props: any) => {
  return (
    <TableCell
      sx={{
        ...borderLeftStyle,
        width: '25%',
        borderLeft: props.omitLeftBorder ? 0 : borderWidth
      }}
      align="center"
    >
      {props.children}
    </TableCell>
  )
}
export const TableCellDtx = (props: any) => {
  return (
    <TableCell
      sx={{
        ...dataCellStyle,
        borderLeft: props.omitLeftBorder ? 0 : borderWidth,
        borderBottom: 'none',
        color: props.color ? props.color : 'grey.900',
      }}
      align="center"
    >
      {props.children}
    </TableCell>
  )
}

export const tableStyles = {
  minWidth: 650,
  borderTop: 1,
  borderColor: 'grey.100',
  borderRadius: borderRadius,
}

export const TableContainerDtx = styled(TableContainer)(
  ({theme}) => ({
    minWidth: 650,
    border: `1px solid ${theme.more.tableOutsideBorderColor}`,
    borderRadius: theme.more.borderRadius,
  })
);

