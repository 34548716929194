import {ApplicationSummary} from "./QueryTypes";

export const nonItApprovedApplications = (applications: ApplicationSummary[]) => {
  return applications ?
    applications.filter((application) => !application.is_enterprise) :
    [];
}
export const itApprovedApplications = (applications: ApplicationSummary[]) => {
  return applications ?
    applications.filter((application) => application.is_enterprise) :
    [];
}

// obsolete name from email address

const capitalize = (aString: string) => {
  return aString.replace(
    /^./,
    (str: string) => str.toUpperCase()
  );
}

export const firstNameFormEmailAddress = (emailAddress: string) => {
  const name = emailAddress.split('@')[0];
  const firstName = name.split('.')[0];
  return capitalize(firstName);
}

