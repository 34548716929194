import {Box, Typography as T, useTheme} from "@mui/material";
import {Cell, Label, Pie, PieChart, Tooltip} from "recharts";
import React from "react";
import {h2FontSize} from "../../control/theme";

const CustomInnerChartLabel = ({
  viewBox,
  total = 54
}: {
  viewBox: any,
  total: number
}) => {
  const {cx, cy} = viewBox;
  // crude centering
  const singleDigitXOffset = 10;
  let numberLeftShift = singleDigitXOffset;
  if (total > 99)
    numberLeftShift = singleDigitXOffset + 22;
  else if (total > 10)
    numberLeftShift = singleDigitXOffset + 10;

  return (
    <React.Fragment>
      <text x={cx - numberLeftShift} y={cy + 7}>
        <tspan
          style={{
            textAlign: 'right',
            fontWeight: 'normal',
            fontSize: h2FontSize,
          }}
        >
          {total}
        </tspan>
      </text>
      <text x={cx - 21} y={cy + 20}>
        <tspan
          style={{
            fontSize: "0.6em",
          }}
        >
          Total Apps
        </tspan>
      </text>
    </React.Fragment>
  );
};
export const DashboardChart = (
  props: { data: any, total: number }
) => {
  const theme = useTheme();

  return (
    // <ResponsiveContainer >
    <div style={{width: '250px'}}>
      <PieChart
        width={250}
        height={250}
        style={{
          margin: "auto",
          textAlign: "center"
        }}
      >
        <Pie
          dataKey="value"
          data={props.data}
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={120}
          // label={(entry) => `${entry.value} ${entry.name} Apps`}
        >
          <Cell fill={theme.more.riskyRed}/>
          <Cell fill={theme.more.andyGreen}/>

          <Label
            position="center"
            content={
              // @ts-ignore
              <CustomInnerChartLabel total={props.total}/>
            }
          />
        </Pie>
        <Tooltip/>
      </PieChart>
      {/*</ResponsiveContainer>*/}
    </div>
  );
}

export function CustomOuterChartLabel(props: {
  count: number,
  label: string,
  color: string
}) {
  return <Box
    sx={{
      width: '25%',
      display: "flex",
      flexDirection: "column",
      flexWrap: 'nowrap',
      alignItems: "center",
      marginBottom: '28px',
    }}
  >
    <Box
      sx={{
        height: "13px",
        width: "13px",
        backgroundColor: props.color,
        marginBottom: '0.5rem',
      }}
    />
    <T
      variant="h2"
      sx={{
        color: props.color,
        marginBottom: 0,
        paddingBottom: 0,
      }}
    >
      {props.count}
    </T>
    <T
      sx={{
        marginTop: '-4px',
        paddingTop: 0,
        fontSize: '0.6em',
        width: '100%'
      }}
    >
      {props.label}
    </T>
  </Box>;
}
