import ReactGA from "react-ga4";
import CssBaseline from "@mui/material/CssBaseline";
import {ThemeProvider} from "@mui/material/styles";

import {QueryClient, QueryClientProvider} from "react-query";
import {Routing} from './Routing';

import {theme} from 'src/control/theme';

// 'dbac691e-aba6-4686-8da9-aa134b463e10; // from onboarding

// Cheap-and-dirty non-reactive application-state
// global variable in a module
declare global {
  // TypeScript song & dance
  interface Window {
    CLIENT_PUB_ID: string;
    PAGE_REF: React.RefObject<unknown>; // React ref to current page for downloading
    SIGNUP_EMAIL: string;
  }
}
// Dodgy - it will use this if we come to the site cold
window.CLIENT_PUB_ID = '-1';

// Configure a React Query client
const oneMinute = 60 * 1000;
const oneHour = 60 * oneMinute;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * oneMinute, // stale after 5 minutes
      cacheTime: 3 * oneHour,   // cache GC'd after 3 hours
    }
  }
});

export const App = () => {
  // console.log('GA', process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID);
  // console.log('OAUTH', process.env.REACT_APP_OAUTH2_REDIRECT_URI);
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID || '');
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routing />
      </ThemeProvider>
    </QueryClientProvider>
  );
}


