import React from 'react';
import {useQuery} from 'react-query';
import {Box, Button, Typography as T, useTheme} from '@mui/material';

import {PageWrapper} from 'src/control/PageWrapper/PageWrapper';
import {AppCounts} from "src/pages/Dashboard/AppCounts";
import {Access} from "src/pages/Dashboard/Access";
import {getDiscoveryData} from 'src/api/Query';
import {useClientPubId} from "src/control/hooks";
import {itApprovedApplications, nonItApprovedApplications} from "src/api/utilities";
import {QueryProvider} from "src/api/QueryProvider";
import {generalBorder} from "../../control/theme";
import {pagePaths} from "../../routes";
import {Blurb} from "./Blurb";
import {aggregateItApproved, aggregateNonItApproved} from "./aggregateHelpers";
import FaqAccordion from 'src/components/FaqAccordion';
import {useNavigate} from "react-router-dom";
import {CustomOuterChartLabel, DashboardChart} from "./PieChart";
import {DtxSpacer} from "../../components/DtxSpacer";
import {CallToActionBlurb} from "../Recommendations/Recommendations";

// const StyledNavLink = styled(NavLink)(({theme}) => (linkStyleFragment));

const SectionHeading = (
  {heading, subtext=''}: {
    heading: string;
    subtext?: string;
  }) => {
  return (<>
    <T
      variant="h5"
      component="span"
      gutterBottom
      sx={{
        color: "black",
        marginTop: "5vh",
        marginLeft: "3vh",
        // marginRight: "3vh",
        marginBottom: "3vh",
        fontWeight: "bold"
      }}
    >
      {heading}
    </T>
    <T
      variant="h5"
      component="span"
      gutterBottom
      sx={{
        color: "black",
        marginTop: "5vh",
        // marginLeft: "3vh",
        marginRight: "3vh",
        marginBottom: "3vh",
        fontWeight: "normal"
      }}
    >
      {subtext}
    </T>
  </>
    );
}

const ButtonViewApps = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <Button
      variant="contained"
      onClick={() => navigate(pagePaths.applications)}
      sx={{
        color: 'white',
        backgroundColor: theme.more.andyBlue,
      }}
    >
      View the full list of applications
    </Button>
  )
}

export const Dashboard = () => {
  const clientPubId = useClientPubId();
  const theme = useTheme();

  const {
    data,
    // isLoading,
    // error
  } = useQuery<any, Error>(
    "discovery",
    () => getDiscoveryData(clientPubId)
  );

  const totolAppCount = data?.application_summaries?.length;
  const nonItApprovedCount = data && nonItApprovedApplications(
    data?.application_summaries
  )?.length;
  const itApprovedCount = data && itApprovedApplications(
    data?.application_summaries
  )?.length;
  const subscriberName = data?.subscriber_name;

  const data02 = [
    {name: 'Non-IT-Approved', value: nonItApprovedCount},
    {name: 'IT-Approved', value: itApprovedCount},
  ];

  const aggregates = data?.application_aggregates;
  const itApprovedAggregates = data && aggregateItApproved(aggregates);
  const nonItApprovedAggregates = data && aggregateNonItApproved(aggregates);
  const hackButtonDisplacement = 2.5; // 3.8;
  return (
    <PageWrapper
      shouldShowNavigation={true}
      shouldShowToolWidget={true}
    >
      <QueryProvider>

        <DtxSpacer />

        <Box
          sx={{
            display: 'grid',
            gridAutoFlow: 'row',
            gap: theme.spacing(5),
            margin: 'auto',
            border: 'none',
          }}
        >
          <T
            variant="h3"
            sx={{
              color: 'black',
              margin: '3vh',
              marginBottom: 0,
              fontWeight: 'bold'
            }}
            gutterBottom
          >
            Welcome {subscriberName}
          </T>
          <T
            variant="h5"
            sx={{
              color: 'black',
              margin: 'auto',
              marginBottom: '0px'
            }}
          >
            Detexian has discovered
            <strong> {totolAppCount} Total Apps</strong>
          </T>
        </Box>

        <DtxSpacer />

        <Box sx={{
          height: '270px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',

        }}>
          <CustomOuterChartLabel
            count={itApprovedCount}
            label="IT-Approved Apps"
            color={theme.more.andyGreen}
          />
          <DashboardChart
            data={data02}
            total={totolAppCount}
          />
          <CustomOuterChartLabel
            count={nonItApprovedCount}
            label="Non-IT-Approved Apps"
            color={theme.more.riskyRed}
          />
        </Box>

        <DtxSpacer space={8}/>

        <Box
          sx={{
            border: generalBorder,
            borderRadius: theme.more.borderRadius,
            padding: theme.spacing(3),
            paddingBottom: theme.spacing(3 + hackButtonDisplacement),
          }}
        >
          <SectionHeading
            heading="Non-IT-Approved Apps"
            subtext=" By Access Type"
          />

          <DtxSpacer space={3}/>

          <Access/>

          <DtxSpacer space={5}/>

          <SectionHeading
            heading="Non-IT-Approved Apps"
            subtext=" By User Count"
          />

          <DtxSpacer space={3}/>

          <AppCounts
            counts={nonItApprovedAggregates}
            color={theme.more.riskyRed}
          />
        </Box>
        <Box
          sx={{
            marginTop: theme.spacing(-hackButtonDisplacement),
          }}
        >
          <ButtonViewApps />
        </Box>
        <DtxSpacer space={8}/>

        <Box
          sx={{
            border: generalBorder,
            borderRadius: theme.more.borderRadius,
            padding: theme.spacing(3),
            paddingBottom: theme.spacing(4),
          }}
        >
        <SectionHeading
          heading="IT-Approved-Apps"
          subtext=" By User Count"
        />

        <DtxSpacer space={3}/>

        <AppCounts
          counts={itApprovedAggregates}
          color={theme.more.andyGreen}
        />
        </Box>

        <DtxSpacer space={8}/>

        <Blurb data={data}/>

        <DtxSpacer space={4}/>

        <FaqAccordion/>


        <CallToActionBlurb>Save Money and Reduce Risk</CallToActionBlurb>

        <DtxSpacer space={1.5}/>
        <ButtonViewApps />

      </QueryProvider>
    </PageWrapper>
   );
}
