import {styled} from "@mui/material/styles";
import Tooltip, {tooltipClasses, TooltipProps} from "@mui/material/Tooltip";
import {borderRadius, generalBorderLine} from "src/control/theme";
import * as React from "react";

const borderColor = 'grey';
const border = `${generalBorderLine} ${borderColor}`

const backgroundColor = 'white';

const defaultTextColor = 'black';
const defaultFontSize = '0.9rem';

export const HtmlTooltip = styled(
  (
    {
      className,
      ...props
    }: TooltipProps
  ) => (
    <Tooltip
      {...props}
      arrow
      placement="top"
      classes={{
        popper: className
      }}
    />
  )
)
(
  ({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      padding: '1.5rem',
      fontSize: defaultFontSize,
      backgroundColor: backgroundColor,
      color: defaultTextColor,
      maxWidth: 250,
      border: border,
      borderRadius: borderRadius,
    },
    // before is needed or the border is a little square
    [`& .${tooltipClasses.arrow}:before`]: {
      color: backgroundColor,
      border: border,
    },
  })
);
