import {styled} from "@mui/system";

export const StyledCloud = styled('img')({
  height: '34vmin',
  pointerEvents: 'none',
});

export const StyledSignupBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'left',
  gap: '34px',
});

export const StyledSignupContainer = styled('div')({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: '700px',
  margin: 'auto',
  marginTop: '0px',
});

export const StyledValuerPropsition = styled('div')(
    ({theme: {palette: {text}}}
  ) => ({
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    fontSize: '1.1rem',
    gap: '5px',
    color: text.primary,
  })
);

export const StyledSignupForm = styled('form')({
  display: 'inline-flex',
  flexDirection: 'column',
  textAlign: 'left',
  width: '250px',
});
