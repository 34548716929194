import {Box, Divider, styled, Typography as T, useTheme} from "@mui/material";
import {DtxSpacer} from "../../components/DtxSpacer";
import * as React from "react";
import {tableBorderLine} from "../../control/theme";

export const StyledUsageValue = styled(T)(({theme}) => ({
  color: theme.more.riskyRed,
  fontSize: '1.2rem',
}));

export const StyledUsageLabel = styled(T)(({theme}) => ({
  fontWeight: 'bold',
}));

export const StyledSectionLabel = styled(T)(({theme}) => ({
  fontWeight: 'bold',
  fontSize: '1.5rem',
}));

export const SpacedDivider = (
  {width = '100%'}: { width?: string }
) => {
  return (
    <Box
      sx={{
        width: {width},
        margin: 'auto',
      }}
    >
      <DtxSpacer space={6}/>
      <Divider/>
      <DtxSpacer space={6}/>
    </Box>);
}

// These probably should be in components
export const RedCallout = ({children}: any) => {
  const theme = useTheme();
  return <Box
    sx={{
      border: `${tableBorderLine} ${theme.more.riskyRed}`,
      borderRadius: theme.more.borderRadius,
      padding: "1rem",
    }}
  >
    {children}
  </Box>
}

export const TextLink = (
  {
    text,
    onClick,
    fontWeight = 'normal',
  }: {
    text: string,
    onClick: any,
    fontWeight?: string,
  }
) => {
  const theme = useTheme();
  return (
    <Box component="span" m="{1}"
      onClick={onClick}
      sx={{
        color: theme.more.andyBlue,
        fontWeight: fontWeight,
        cursor: "pointer",
        ":hover": {
         opacity: theme.more.hoverOpacity,
        }
      }}
    >
      {text}
    </Box>
  )
}

export const RedText = ({text}: {text: string}) => {
  const theme = useTheme();
  return (
    <Box
      component="span"
      sx={{color: theme.more.riskyRed}}
    >
      {text}
    </Box>
  );
}


