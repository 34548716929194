import React, {useContext} from "react";
import {
  TableContainerDtx,
  TableCellDtx,
  TableHeaderCellDtx
} from "src/pages/Dashboard/styles";
import {Typography as T} from '@mui/material';
import StorageIcon from '@mui/icons-material/Storage';
import Email from '@mui/icons-material/Email';
import DriveFileMove from '@mui/icons-material/DriveFileMove';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import {useTheme} from "@mui/material";
import {QueryProvider, QueryContext} from "../../api/QueryProvider";
import {aggregateNonItApproved} from "./aggregateHelpers";

// Refectored this out because I couldn't figure out a way
// for it to access the queryContext data from within the
// Access component, below
const AccessConsumer = () => {
  const theme = useTheme();
  const queryData = useContext(QueryContext);
  const aggregates = queryData?.application_aggregates;
  return <TableContainerDtx>
      {/*<TableContainer component={Paper} sx={tableStyles}>*/}
    <Table
      aria-label="simple table"
      id="user-consented-access-table"
    >
      <TableHead>
        <TableRow>
          <TableHeaderCellDtx
            omitLeftBorder={true}
          >{<>
              <StorageIcon sx={{fontSize: '1.5rem', color: 'gray'}} />
              <T sx={{fontSize: '0.85rem'}}>Write Access</T>
            </>}
          </TableHeaderCellDtx>
          <TableHeaderCellDtx>{<>
              <Email sx={{fontSize: '1.5rem', color: 'gray'}} />
              <T sx={{fontSize: '0.85rem'}}>Email Access</T>
            </>}
          </TableHeaderCellDtx>
          <TableHeaderCellDtx>{<>
              <DriveFileMove sx={{fontSize: '1.5rem', color: 'gray'}} />
              <T sx={{fontSize: '0.85rem'}}>Calendar Access</T>
            </>}
          </TableHeaderCellDtx>
          <TableHeaderCellDtx>{<>
              <CalendarMonth sx={{fontSize: '1.5rem', color: 'gray'}} />
              <T sx={{fontSize: '0.85rem'}}>File Access</T>
            </>}
          </TableHeaderCellDtx>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          {/*<TableCell component="th" scope="row">*/}
          {/*  {row.name}*/}
          {/*</TableCell>*/}
          <TableCellDtx
            color={theme.more.riskyRed}
            omitLeftBorder={true}
          >
            {/*{accessCount("write_access", rows)}*/}
            {aggregateNonItApproved(aggregates)?.write_access}
          </TableCellDtx>
          <TableCellDtx color={theme.more.riskyRed}>
            {aggregateNonItApproved(aggregates)?.email_access}
          </TableCellDtx>
          <TableCellDtx color={theme.more.riskyRed}>
            {aggregateNonItApproved(aggregates)?.calendar_access}
          </TableCellDtx>
          <TableCellDtx color={theme.more.riskyRed}>
            {aggregateNonItApproved(aggregates)?.file_access}
          </TableCellDtx>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainerDtx>;
}

export const Access = () => {
  return  (
    <QueryProvider>
      <AccessConsumer/>
    </QueryProvider>
  )
}
