import riskchart1 from "../../assets/images/riskchart1.png";
import {DtxSpacer} from "../../components/DtxSpacer";
import {Box} from "@mui/material";
import * as React from "react";
import {StyledSectionLabel, StyledUsageLabel, StyledUsageValue} from "./styles";
import { RiskBlurb } from "./RiskBlurb";
import {getAppsOfTypeEnterpriseOrUser} from "../Applications/Applications";

export const nonITApprovedAppPhraseColor = 'grey.700';

const RiskColumn = ({
  labelFirstLine,
  labelSecondLine,
  countAndLabel,
}: any) => {
  return (
    <Box sx={{width: '30%'}}>
      <StyledUsageLabel sx={{color: nonITApprovedAppPhraseColor}}>
        {labelFirstLine}
      </StyledUsageLabel>
      <StyledUsageLabel>{labelSecondLine}</StyledUsageLabel>
      <DtxSpacer space={1.5}/>
      <StyledUsageValue>
        {countAndLabel}
      </StyledUsageValue>
    </Box>
  )
}

export const Risk = (props: { data: any }) => {
  const nonItApprovedApps = props.data?.application_summaries;
  const nonItApprovedAppCount = getAppsOfTypeEnterpriseOrUser(
    nonItApprovedApps,
    false // specify non-IT-Approved apps
  )?.length

  const nonItAppWithEmailAccessCount = nonItApprovedApps.filter(
    (app: {email_access: boolean}) => app.email_access === true
  ).length

  const nonItAppUsersWithoutSSO = props.data?.summary.no_sso_user

  return <>
    <img
      src={riskchart1}
      alt="Detexian"
      height="55px"
    />
    <StyledSectionLabel>Risk</StyledSectionLabel>
    <DtxSpacer/>
    <Box
      sx={{
        display: "flex",
        margin: "auto",
        justifyContent: "center",
      }}
    >
      <RiskColumn
        labelFirstLine="Non-IT-Approved Apps"
        labelSecondLine="Total"
        countAndLabel={`${nonItApprovedAppCount}`}
      />
      <DtxSpacer orientation="vertical" space={3}/>
      <RiskColumn
        labelFirstLine="Non-IT-Approved Apps"
        labelSecondLine="with Access to Email"
        countAndLabel={nonItAppWithEmailAccessCount}
      />
      <DtxSpacer orientation="vertical" space={3}/>
      <RiskColumn
        labelFirstLine="Non-IT-Approved App"
        labelSecondLine="Users without SSO"
        countAndLabel={`${nonItAppUsersWithoutSSO}`}
      />
    </Box>
    <DtxSpacer space={5}/>
    <RiskBlurb
      data={props.data}
      nonItAppUsersWithoutSSO={nonItAppUsersWithoutSSO}
      nonItAppWithEmailAccessCount={nonItAppWithEmailAccessCount}
    />
  </>;
}



          // `${nonItAppWithEmailAccessCount} App
          //  ${nonItAppWithEmailAccessCount=== 1 ? '' : 's'}`
