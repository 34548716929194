import {
  gql,
  // GraphQLClient,
  request,
} from "graphql-request";
import dayjs from "dayjs";
import {mockResponse} from "./mock/mock";
import {DiscoveryResponseType} from "./QueryTypes";
import {andyBlue} from "../control/theme";

const graphQLURL = process.env.REACT_APP_GRAPHQL_API_ENDPOINT || "";

// query getDiscoveryData($client_pub_id: uuid) {
//   clients_client(where:{pub_id:{_eq:$client_pub_id}}){

const discoveryDataQuery = gql`
query getDiscoveryData {
  clients_client {
    name
    expiry
    subscriber_name
    application_aggregates{
      is_enterprise
      email_access
      file_access
      calendar_access
      write_access
      over25_count
      tenTo25_count
      fiveTo10_count
      lessThan5_count
    }
    application_summaries{
      application_pub_id
      application_name
      is_enterprise
      email_access
      file_access
      calendar_access
      write_access
      user_count
    }
  }
  duplicated_user_apps {
    duplicate_count
    example
  }
  client_application_summary {
    enterprise_user_count
    non_enterprise_user_count
    total_users
    unique_users
    savings_lower
    savings_upper
    no_sso_enterprise
    no_sso_user
  }
  client_examples {
    user
    app_user_app
    app_user_name
  }
}`;

const labelTag = '<h3>'; // used by catch to identify the below

const styledErrorLink = (
  intro: string = 'Unexpected error',
  linkLabel: string = 'Go to Sign Up page',
  url: string = '/'
) => {
  return `${labelTag}${intro}</h3>` +
    `<a ` +
    `style="color: ${andyBlue}; text-decoration: none;" ` +
    `href="${url}">${linkLabel}</a>`
}

// This is less straightforward now that it's shared between the
// query and the mutation.  Maybe it should be split / parameterized.
const handleErrorsAndExpiry = (
  client_pub_id: any,
  data: any) => {

  // handle error field in data (untested)
  if (data.errors) {
    const errorMessage =
      data?.errors?.[0]?.message ||
      'missing error message';
    console.error('error in response', {client_pub_id}, {errorMessage})
    throw new Error(styledErrorLink());
  }

  // handle no data at all
  if (
    ! (
      data?.clients_client?.length ||
      data?.upgrade // for the 14-Day-Trial mutation
    )
  ) {
    console.log({data});
    const errorMessage = 'uuid returned no data';
    console.error(errorMessage, {client_pub_id})
    throw new Error(styledErrorLink());
  }

  // identify expired uuid (no apps, no aggregates, after expiry)
  const info = data.clients_client?.[0];

  if (info) { // info won't exist for a 14-Day-Trial Mutation

    const isExpired = () =>
      info?.expiry && dayjs().isAfter(dayjs(info.expiry));

    // not expired, but no data
    if (!isExpired() && !info?.application_aggregates.length) {
      const errorMessage = 'valid uuid and not expired - but no data';
      console.error(errorMessage, {client_pub_id})
      throw new Error(styledErrorLink());
    }

    if (isExpired()) {
      console.error('expired uuid for ', info.name, {client_pub_id})
      throw new Error(
        styledErrorLink(
          `${info.subscriber_name}, your reports have expired`
        )
      );
    }
  }
}

// Simple transform: get data record or create a stub record.
// With the above error handling, we might never use the stub.
// Still, maybe a useful reference.
const transform = (data: any): DiscoveryResponseType =>
  data?.clients_client?.[0] ||
  {
    application_summaries: [],
    application_aggregates: [],
    expiry: Date(),
    name: '',
    subscriber_name: '',
  };

const sharedErrorCatch = (
  error: {message: any},
  client_pub_id: string
 ) => {
    // throw to React Query

    // if we threw an html error message, above, re-throw it
    if (error.message.startsWith(labelTag)) {
      throw new Error(error.message)
    }
    // otherwise throw our generic error and log the actual error
    else {
      console.error('catch - caught query error', error.message, {client_pub_id})
      throw new Error(styledErrorLink());
    }
  }

export const getDiscoveryData =
async (client_pub_id: any): Promise<any> => {
  if(client_pub_id === '-1' || client_pub_id === undefined || client_pub_id === '') {
    const result =  mockResponse(); // mock
    return result;
  }
  return request({
    url: graphQLURL,
    document: discoveryDataQuery,
    requestHeaders: {
      'x-hasura-client-pub-id': client_pub_id,
    },
  })
  .then((data: any) => {
    // console.log({data});
    handleErrorsAndExpiry(client_pub_id, data);

    const result = transform(data)
    // console.log('transformed', {result});
    result.summary = data.client_application_summary?.[0];
    result.duplicatedAppCount = data.duplicated_app_count?.length;
    result.clientExamples = data.client_examples?.[0];
    return result;
  })
  .catch((error) => sharedErrorCatch(error, client_pub_id))
};

// 14-Day Trial mutation

const request14DayTrialMutation = gql`
  mutation Request14DayTrial($pubId: String!) {
    upgrade(req: {id: $pubId}) {
      id
    }
  }
`;

export const request14DayTrial = async (
  client_pub_id: any): Promise<any> => {
  if(client_pub_id === '-1' || client_pub_id === undefined || client_pub_id === '') {
    throw new Error('no user id');
  }
  return request({
    url: graphQLURL,
    document: request14DayTrialMutation,
    variables: {pubId: client_pub_id},
    requestHeaders: {
      'x-hasura-client-pub-id': client_pub_id,
    },
  })
  .then((data: any) => {
    console.log({data});
    handleErrorsAndExpiry(client_pub_id, data);

    const result = data;
    return result;
  })
  .catch((error) => sharedErrorCatch(error, client_pub_id));
}



// With this class vesion you can set up the url once for all queries

// export const getDiscoveryData = async (
// client_pub_id: string) => {
//   const client = new GraphQLClient(graphQLURL);
//
//   return client
//     .request(discoveryDataQuery, {client_pub_id})
//     .then((data: any) => {
//       // console.log({data});
//       const result = transform(data)
//       console.log({result});
//       return result;
//     })
//     .catch(error => () =>
//     console.log('getDiscoveryData', error.message)
//   );
// };

