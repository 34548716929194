import React, {useEffect, useRef} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {styled} from '@mui/system';
import {Box, Divider, Typography, useTheme} from '@mui/material';
import '../App.css';
import background from 'src/assets/images/background.png'
import {ReactChildren} from "../Types";
import {Header} from "./Header";
import {pageContentWidth,} from "src/control/theme";
// import {DiscoverUntrusted} from './DiscoverUntrusted';
import {useClientPubId} from "src/control/hooks";
import {Navigation} from "src/control/PageWrapper/Navigation";
import {dev_goToNextPage} from "./dev_goToNextPage";
import {QueryProvider} from "../../api/QueryProvider";
import {FinePrint} from "./FinePrint";
import {DtxSpacer} from "../../components/DtxSpacer";
import {magicHeaderHeightHack} from "../../pages/Applications/styles";
import {SpacedDivider} from "../../pages/Recommendations/styles";

const StyledPageBackground = styled('div')({
  backgroundImage: `url(${background})`,
  backgroundColor: 'white',
  backgroundRepeat: 'repeat-x',
  minHeight: `calc(100vh - (${magicHeaderHeightHack} + 21px))`,
  // margin: 'auto',
  // width: pageContentWidth,
})

const StyledPageContainer = styled('div')({
  maxWidth: pageContentWidth, // PAGE WIDTH
  margin: "50px auto",
})

type props = {
  children: ReactChildren;
  shouldShowToolWidget?: boolean;
  shouldShowNavigation?: boolean;
  footerMessage?: string | undefined;
}

export const PageWrapper = ({
  children,
  shouldShowToolWidget = false,
  shouldShowNavigation = false,
  footerMessage

}: props) => {
  // ReactGA.send({ hitType: "PageWrapper", page: window.location.href});
  const navigate = useNavigate();
  const {
    pathname,
    hash,
    key
  } = useLocation();
  const theme = useTheme();

  const clientPubId = useClientPubId();
  // const goToNextPage = extracted(pathname, navigate, clientPubId);

  // for scrolling to an anchor
  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  const pageRef = useRef(null); // ref to whole page stored globally below, used by ToolsWidget screenshot

  const result = (
    <Box
      ref={pageRef}
      sx={{
        height: '100vh',
      }}
    >
      <QueryProvider shouldAlwaysShowChild={true}>
        <Header
          shouldShowToolWidget={shouldShowToolWidget}
        />
      </QueryProvider>

      <StyledPageBackground>
        <Divider sx={{backgroundColor: 'white'}}/>

        <StyledPageContainer className="App">
          <Typography
            variant="h1"
            gutterBottom
            onClick={() => dev_goToNextPage(
              pathname,
              navigate,
              clientPubId
            )}
            sx={{color: theme.more.andyBlue}}
          >
            Save Money and Reduce Risk
          </Typography>

          <DtxSpacer />

          {/*{shouldShowNavigation ? <Navigation /> : <DiscoverUntrusted />}*/}
          {shouldShowNavigation && <Navigation/>}

          {/*Cheap and dirty way to make the children area a min height*/}
          <div style={{border: 'none', minHeight: '50vh', height: 'auto'}}>
            {children}
          </div>
          {/*{*/}
          {/*  shouldShowNavigation &&*/}
          {/*  <FooterMessage*/}
          {/*    footerMessage={footerMessage}*/}
          {/*  />*/}
          {/*}*/}
          <SpacedDivider width='80%'/>
          <FinePrint />
        </StyledPageContainer>
      </StyledPageBackground>
    </Box>
  );
  window.PAGE_REF = pageRef; // ToolsWidget download uses this
  return result;
}
