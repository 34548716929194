import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(duration);
dayjs.extend(relativeTime);

// const dayInMilliseconds = 1000 * 60 * 60 * 24;

export const formatDateTime = (
  date: string,
  format = 'DD/MM/YYYY - HH:mm'
): string => dayjs(date).format(format);

export const formatDurationFromNow = (date: string) =>
  dayjs.duration(dayjs().diff(date)).humanize();

export const preferredDateFormat = (date: string) =>
  formatDateTime(date, 'D MMM YYYY');

export const preferredTimeFormat = (date: string) =>
  formatDateTime(date, 'h:mma');

export const dateFormatWithDayOfWeek = (date: string) =>
  formatDateTime(date, 'ddd D MMM YYYY');

export const daysUntilDate = (laterDate: string) => {
  const untilInDays = dayjs(laterDate).diff(dayjs(), 'day', true);
  return Math.floor(untilInDays);
}

