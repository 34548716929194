import * as React from 'react';
import {styled} from '@mui/material/styles';
// import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import MuiAccordion, {AccordionProps} from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import {Box, useTheme} from "@mui/material";
import {andyBlue} from "src/control/theme";
import {grey} from "@mui/material/colors";

const Accordion = styled(
  (props: AccordionProps) => (
    <MuiAccordion
      disableGutters
      elevation={0}
      square {...props}
    />
  )
) (
  ({theme}) => ({
    // border: `1px solid ${theme.palette.divider}`,
    // '&:not(:last-child)': {
    //   borderBottom: 0,
    // },
    '&:before': {
      display: 'none',
    },
  })
);

const AccordionSummary = styled(
  (props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={<ArrowRightIcon sx={{
        fontSize: '1.5rem',
        color: andyBlue,
      }} />}
      {...props}
    />
  )
)(
  ({theme}) => ({
    // backgroundColor:
    //   theme.palette.mode === 'dark' ?
    //     'rgba(255, 255, 255, .05)' :
    //     'rgba(0, 0, 0, .03)',
    textAlign: 'center',
    flexDirection: 'row-reverse', // moves the position of the arrow icon to the left of the Summary word (FAQs)
    // none of these center the icon and FAQ pair
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    // so have to do this
    paddingLeft: '43%',
    // width: '50%',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(0),
    },
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
  padding: theme.spacing(2),
  // borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

type questionProps = {
  question: string;
  answer: any;
}

const FAQ = ({question, answer}: questionProps) => {
  return (
    <Box sx={{
      textAlign: 'left',
    }}>
      <Typography
        sx={{
          fontWeight: 'bold',
          color: grey[900],
          fontSize: '0.9rem',
          marginBottom: '5px',
        }}>
        {question}
      </Typography>
      <Box
        sx={{
          fontSize: '.9rem'
        }}
        // gutterBottom
      >
        <div>{answer}</div>
      </Box>
    </Box>
  )
}

export default function FaqAccordion() {
  const theme = useTheme();
  const [expanded, setExpanded] = React.useState<string | false>('');

  const handleChange = (panel: string) => (
    event: React.SyntheticEvent, newExpanded: boolean
  ) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div style={{width: '70%', margin: 'auto'}}>
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          // give more of an impression of being centered
          sx={{marginLeft: '-8px'}}
        >
          <Typography
            sx={{
              color: andyBlue,
              fontWeight: '200', // Doesn't work - how to change weight?  See Lato import in index.tsx
              fontSize: '1.3rem',
              ':hover': {
                opacity: theme.more.hoverOpacity,
              }
            }}
          >
            FAQ
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FAQ
            question="What are IT-Approved Apps?"
            answer='SaaS apps set up and maintained by the IT team with security attributes such as SSO enablement, Azure AD group assignment and user restrictions.'
          />
          <br />
          <FAQ
            question='What are “Non-IT-Approved Apps”?'
            answer={
              <span>
              <span>SaaS apps which users connect to the organization’s SaaS ecosystem themselves, for personal use or to enhance business value for their unit.
              </span>
              <br />
              <p>A user can approve risky app access in two ways:</p>
              <ul
                style={{
                  margin: 0,
                  marginLeft: '17px',
                  padding: 0
                }}
              >
                <li>
                  Clicking a "Sign up with Microsoft" button
                </li>
                <li>
                  Signing up with the app then approving access to Microsoft 365
                </li>
              </ul>
              </span>
            }
          />
          <br />
          <FAQ
            question="How does Detexian obtain this information?"
            answer={
              <span>Detexian has built software to leverage read-only API access to collect and triage configuration data
                from Microsoft 365 service API. No agent or network tap is required. You will clearly see the scopes required before consenting access to Detexian. You can revoke permissions any time you like
              </span>
            }
          />
          <br />
          <FAQ
            question="What are some expected outcomes from Detexian SaaS App Discovery?"
            answer={
            <ul
              style={{
                margin: 0,
                marginLeft: '17px',
                padding: 0
              }}
            >
              <li>
                Take stock of IT-Approved Apps and Non-IT-Approved Apps in your Microsoft 365 environment. Statistically, 77% of apps are managed by business teams without direct IT involvement.

              </li>
              <li>
                Disable Risky Non-IT-Approved Apps and reduce attack surface
              </li>
              <li>
                Elevate Non-IT-Approved Apps with high degrees of penetration to IT-Approved apps with the right security attributes
              </li>

              <li>Identify duplicates and consolidate subscriptions
              </li>
              <li>Establish baseline for continuous monitoring
              </li>
            </ul>
          }
          />
       </AccordionDetails>
      </Accordion>
    </div>
  );
}
