import {Box, useTheme} from "@mui/material";
import React from "react";
import {styled} from "@mui/system";

const StyledFinePrint = styled('p')({
  marginTop: 'auto',
  marginBottom: '0px',
  // margin: 'auto',
  // position: 'sticky',
  // left: 0,
  // right: 0,
  // bottom: 0,
  fontSize: 'calc(8px + .5vmin)'
});

export const Terms = ({children}: any) => {
  const theme = useTheme();
  return (
    <Box component="span" m="{1}"
         onClick={
           () => window.open(
             'https://www.detexian.com/terms-and-conditions',
             '_blank'
           )
         }
         sx={{
           color: theme.more.andyBlue,
           cursor: "pointer",
           ":hover": {
             opacity: theme.more.hoverOpacity,
           }
         }}
    >
      {children}
    </Box>
  )
}

export const Privacy = ({children}: any) => {
  const theme = useTheme();
  return (
    <Box
      component="span"
      m="{1}"
      onClick={
        () => window.open(
          'https://www.detexian.com/privacy-policy',
          '_blank'
        )
      }
      sx={{
        color: theme.more.andyBlue,
        cursor: "pointer",
        ":hover": {
          opacity: theme.more.hoverOpacity,
        }
      }}
    >
      {children}
    </Box>
  )

}

const BulletDivider = () => (
  <span
    style={{
      color: 'gray',
      margin: '0px 10px',
    }}
  >•</span>)

export const FinePrint = () => {
  return <StyledFinePrint>
    <span
      style={{color: "grey"}}
    >
      Copyright Detexian 2022 All rights Reserved
    </span>
    <BulletDivider/>
    <Terms>
      Terms & Conditions
    </Terms>

    <BulletDivider/>

    <Privacy>
      Privacy Policy
    </Privacy>
  </StyledFinePrint>;
}
