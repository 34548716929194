import {useParams} from "react-router-dom";

export const useClientPubId = () => {
  const {clientPubId} = useParams();

  if (clientPubId) {
    window.CLIENT_PUB_ID = clientPubId; // save it to build urls to this and Overview page
  }

  return window.CLIENT_PUB_ID
}
