import * as React from 'react';
import {PageWrapper} from 'src/control/PageWrapper/PageWrapper';
import {privacyRaw} from 'src/pages/privacyRaw';

export const Privacy = () => {
  return (
    <PageWrapper>
      <p>{privacyRaw}</p>
    </PageWrapper>
  )
}

    // <div dangerouslySetInnerHTML={{__html: termsRaw}} />
