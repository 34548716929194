import React, { FC } from 'react';
import {Box} from "@mui/material";
import {borderRadius, generalBorder} from "../control/theme";
import alert from "src/assets/images/alert.png";

type errorScreenProps = {
  message: string;
}

export const ErrorScreen: FC<errorScreenProps> = (props) => (
  <Box
    sx={{
      display: "grid",
      height: '40vh',
      padding: '10%',
      marginBottom: '10%',

    }}
  >
    <Box
      sx={{
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        border: generalBorder,
        borderRadius: borderRadius,
        paddingTop: 0,
      }}

    >
      <img
        src={alert}
        alt="Alert Error"
        height="21%"
      />
      <div dangerouslySetInnerHTML={{__html: props.message}} />
      <br /> <br />
    </Box>
  </Box>
);
