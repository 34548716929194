import * as React from 'react';
import { PageWrapper } from 'src/control/PageWrapper/PageWrapper';
import {termsRaw} from 'src/pages/termsRaw';

export const Terms = () => {
  return (
    <PageWrapper>
        <p>{termsRaw}</p>
    </PageWrapper>
  )
}

    // <div dangerouslySetInnerHTML={{__html: termsRaw}} />
