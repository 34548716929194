import {Box} from "@mui/material";
import React from "react";
import {ReactChildren} from "../control/Types";

interface props {
  children: ReactChildren,
  width?: string,
  paddingBottom?: string,
}

export const BoxDetexian = ({
  children,
  width,
  paddingBottom,
}: props) => {

  const sx: {
    // p: number,
    paddingTop: number,
    paddingLeft: number,
    paddingRight: number,
    border: string,
    borderRadius: string,
    margin: string,
    width?: string,
    paddingBottom?: string,

  } = {
    paddingTop: 2,
    paddingLeft: 0,
    paddingRight: 0,
    border: '1px solid lightgray',
    borderRadius: '8px',
    margin: 'auto',
  }

  if (width) sx.width = width;
  if (paddingBottom) sx.paddingBottom = `${paddingBottom}`;

  return (
    <Box sx={sx}>
      {children}
    </Box>
  );
}
