import React from "react";
import {AppBar, Box, Toolbar, useTheme} from "@mui/material";
import logo from "src/assets/images/logo.png";
import {ToolsWidget} from "./ToolsWidget";
import {headerHeight} from "../theme";

type props = {
  shouldShowToolWidget: boolean;
}

export const Header = ({
    shouldShowToolWidget,
}: props) => {
  const theme = useTheme();

  return <AppBar
    sx={{
      display: "flex",
      backgroundColor: "white",
      position: "static", // so it takes up vertical space
      borderRadius: 0,
      padding: "0 3% 0",
      height: headerHeight,
    }}
  >
    <Toolbar
      sx={{
        width: "100%",
        display: "flex",
      }}
      disableGutters
    >
      {/*
        This invisible-component trick is a simple way to center the
        Logo but right justify the email-share-download block.  Need
        to keep an eye on performance though.
      */}
      {shouldShowToolWidget &&
        <ToolsWidget
          visibility="hidden"
        />
      }

      <Box
        sx={{
          paddingTop: '18px',
          margin: 'auto',
          height: theme.more.headerHeight,
          "&:hover": {
            cursor: "pointer",
            opacity: theme.more.hoverOpacity,
          }
        }}
        onClick={() => window.open('https://detexian.com', '_blank')}
      >
        <img
          src={logo}
          alt="Detexian"
          height="70%"
        />
      </Box>

      {shouldShowToolWidget &&
        <ToolsWidget
        />
      }
    </Toolbar>
  </AppBar>;
}
