import {BlurbWrapper} from "../../components/BlurbWrapper";
import {Box, useTheme} from "@mui/material";
import {riskyBulletColor} from "../../control/theme";
import Typography from "@mui/material/Typography";
import {BookmarkTwoTone} from "@mui/icons-material";
import {daysUntilDate} from "../../control/dates";
import React from "react";
import {DtxSpacer} from "../../components/DtxSpacer";

export function Blurb(props: {data: any}) {
  const theme = useTheme();
  return <BlurbWrapper>
    <Box
      sx={{
        margin: "auto",
        padding: "5% 8%",
        border: `1px solid ${riskyBulletColor}`,
        borderRadius: theme.more.borderRadius
      }}
    >
      <Typography
        variant="body1"
        gutterBottom
        textAlign="center"
      >
        Data often flows between SaaS apps without anyone knowing.
        <br/>
        This can lead to multiple risks unknown to the organization.
      </Typography>
    </Box>

    <DtxSpacer space={3}/>

    <Box
      sx={{
        display: "grid",
        gridAutoFlow: "column",
        gap: theme.spacing(1),
        margin: "auto",
        marginTop: "1.5rem",
        border: "none",
        color: "gray",
      }}
    >
      <BookmarkTwoTone
        style={{
          color: theme.more.andyBlue,
          fontSize: "3rem",
          marginLeft: "-8px",
        }}
      />
      <Typography
        variant="body1"
        gutterBottom
        sx={{
          paddingLeft: 0,
          fontStyle: "italic",
        }}
      >
        {`To view your reports later, bookmark this page or use the link we
                emailed to you. Note these reports will expire in ${
          daysUntilDate(props.data?.expiry)
        } days.`}
      </Typography>
    </Box>
  </BlurbWrapper>;
}
