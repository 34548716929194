import React from 'react';
import {PageWrapper} from 'src/control/PageWrapper/PageWrapper';
import Typography from '@mui/material/Typography';
import {useTheme} from "@mui/material";
import Check from '@mui/icons-material/Check';
import {BoxDetexian} from "../components/BoxDetexian";
import {BlurbWrapper} from "../components/BlurbWrapper";
import {AnyQuestionsOrHelp} from "./Signup/Signup";
import {DtxSpacer} from "../components/DtxSpacer";


export const TrialSuccess = () => {
  const theme = useTheme();
  const spacing = theme.spacing(5);

  return (
    <PageWrapper>
      <BoxDetexian
        width="85%"
        paddingBottom={spacing}
      >
        <Check
          sx={{
            color: theme.more.andyGreen,
            fontSize: '2.5rem',
            fontWeight: 'bold',
          }}
        />
        <Typography
          sx={{
            fontSize: '1.4rem',
            fontWeight: 'bold',
            color: 'grey.600', // theme.palette.text.secondary,
          }}
        >
          Thanks for activating your Detexian SSPM trial
        </Typography>
        <DtxSpacer />
        <BlurbWrapper sx={{
          margin: 'auto',
          width: '70%'}}>
          <Typography
            variant="body1"
            gutterBottom
          >
            Our system is now analyzing your SaaS configuration and upgrading your access to include access to our full portal.
          </Typography>
          <DtxSpacer />
          <Typography
            variant="body1"
            gutterBottom
            sx={{
              // fontWeight: 'bold',
            }}
          >
            Detexian SSPM will <strong>email you a password reset link</strong> (from support@detexian.com), within 1 business day.
          </Typography>
          <DtxSpacer />
          <Typography
            variant="body1"
            gutterBottom
          >
            To get full value from your 14-Day trial we recommend adding three or more SaaS to our platform. We'll be in touch within 2 business days to assist with this simple process.
          </Typography>
        </BlurbWrapper>
      </BoxDetexian>
      <br />
      <br />
      <AnyQuestionsOrHelp />
    </PageWrapper>
   );
}
