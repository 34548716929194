import React, {useState} from 'react';
import ReactGA from "react-ga4";
import {
  Avatar,
  Box,
  Button,
  Link,
  Slide,
  TextField,
  useTheme,
} from "@mui/material";
import {TransitionProps} from "@mui/material/transitions";

import 'src/control/App.css';
import cloud from 'src/assets/images/heroCloud.png';
import microsoftButtonIcon from 'src/assets/images/microsoftButtonIcon.png';
import {PageWrapper} from 'src/control/PageWrapper/PageWrapper';
import {
  StyledCloud,
  StyledSignupBlock,
  StyledSignupContainer,
  StyledSignupForm,
  StyledValuerPropsition,
} from "src/pages/Signup/styles";
import Typography from "@mui/material/Typography";
import FaqAccordion from "src/components/FaqAccordion";
import {useNavigate} from 'react-router-dom';
import {pagePaths} from "src/routes";
import {dataGridRowBorder, linkStyleFragment} from "../../control/theme";
import {DtxSpacer} from "../../components/DtxSpacer";

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function AnyQuestionsOrHelp() {
  return <p>Any question or help: <Link
    href="mailto:support@detexian.com"
    sx={linkStyleFragment}
  >
    support@detexian.com
  </Link>
  </p>;
}

// This regex matches the built-in html 5 email address check.
// That is a is a willful violation of RFC 5322, which defines
// a syntax for e-mail addresses that is simultaneously too
// strict (before the "@" character), too vague (after the
// "@" character), and too lax (allowing comments,
// whitespace characters, and quoted strings in manners
// unfamiliar to most users) to be of practical use here.

const isValidEmailAddress = (email: string) => {
  return email.match(
    /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
  );
};

// for Andy's centered bullets (centered li keeps the bullets left aligned)
const Bullet = () => <span>&#8226;</span>;

export const Signup = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [email, setEmail] = useState('');
  const [hasEmailError, setHasEmailError] = useState(false);

  const emailErrorHelperText = () => {
    let result = undefined;
    if (hasEmailError) {
      result = 'Please enter a valid email address';
    }
    return result;
  }

  const emailErrorLabel = () => {
    let result = undefined
    if (hasEmailError) {
      result = 'check';
    }
    return result;
  }

  const handleSubmit = (event: React.SyntheticEvent) => {
    if (isValidEmailAddress(email)) {
      setHasEmailError(false);
      ReactGA.event({
        category: "Signup",
        action: "Submit email",
        label: email, // optional
        // value: 34, // optional, must be a number
        // nonInteraction: true, // optional, true/false
        // transport: "xhr", // optional, beacon/xhr/image
      });
      window.SIGNUP_EMAIL = email;
      // console.log({email});
      event.preventDefault();
      navigate(pagePaths.agree)
    }
    else {
      setHasEmailError(true);
    }
  }

  const onEmailAddressBlur = (event: React.SyntheticEvent) => {
    if (
      isValidEmailAddress(email) ||
      email === '' ||
      email === undefined
    ) {
      setHasEmailError(false);
    }
    else {
      setHasEmailError(true);
    }
  }

  const onEmailAddressChange = (event: any) => {
    setEmail(event.target.value);
    setHasEmailError(false);
  }

  const onEmailAddressFocus = (event: any) => {
    // setEmail(event.target.value);
    setHasEmailError(false);
  }

  return (
    <PageWrapper
      shouldShowNavigation={false}
      shouldShowToolWidget={false}
    >
      {/*<DtxSpacer space={1} />*/}
      <StyledValuerPropsition>
        <div><Bullet/> Find Duplicate Subscriptions</div>
        <div><Bullet/> Discover Non-IT-Approved Apps</div>
        <div><Bullet/> Identify Inactive and Ghost Users</div>
        <div><Bullet/> Reduce unknown and malicious app risk</div>
      </StyledValuerPropsition>
      <DtxSpacer />

      <StyledCloud
        src={cloud}
        alt="system diagram"
      />
      <Box sx={{
        display: 'grid',
        gridAutoFlow: 'row',
        gap: theme.spacing(5),
      }}
      >
        <div>
          <Typography
            variant="h5"
            component="div"
            sx={{
              fontWeight: 'bold',
              color: theme.palette.text.primary
            }}
          >
            Free, 2 min to onboard
          </Typography>
          <Typography
            variant="h5"
            component="div"
            sx={{
              // these accomplish nothing
              // fontWeight: 300,
              // fontFamily: 'lato'
            }}
          >
            (Read-only access)
          </Typography>
        </div>

        <StyledSignupContainer>
          <StyledSignupBlock>


            <Box
              sx={{
                border: dataGridRowBorder,
                borderRadius: theme.more.borderRadius,
                margin: 'auto',
                padding: theme.spacing(2),
              }}
            >
              <h3 style={{
                marginTop: '0',
                paddingTop: '0',
                color: 'gray',
              }}>Try NOW</h3>
              <StyledSignupForm onSubmit={handleSubmit}>
                <TextField
                  size="small"
                  placeholder="Company email*"
                  error={hasEmailError}
                  label={emailErrorLabel()}
                  helperText={emailErrorHelperText()}
                  value={email}
                  // onChange={(event) => setEmail(event.target.value)}
                  onChange={onEmailAddressChange}
                  onBlur={onEmailAddressBlur}
                  onFocus={onEmailAddressFocus}
                />
                <Button
                  variant="contained"
                  sx={{
                    maxHeight: '35px',
                    maxWidth: '190px',
                    marginTop: '8px',
                    color: 'white',
                    paddingRight: 0,
                    backgroundColor: theme.more.submitButtonColor,
                  }}
                  startIcon={
                    <Avatar
                      src={microsoftButtonIcon}
                      variant="square"
                      sx={{
                        backgroundColor: 'white',
                        padding: '2px',
                        borderRadius: '3px',
                        height: '26px',
                        width: '26px',
                        marginLeft: '-21px',
                      }}
                    />
                  }
                  type="submit"
                >
                  Sign up with Microsoft
                </Button>
                <Typography
                  sx={{
                    marginTop: '0.3rem',
                    fontStyle: 'italic',
                    color: theme.palette.text.secondary,
                    fontSize: '0.87rem'
                  }}
                >
                  * Global Admin or Company Admin required
                </Typography>
              </StyledSignupForm>
            </Box>

          </StyledSignupBlock>
        </StyledSignupContainer>
        <FaqAccordion/>
        <AnyQuestionsOrHelp />
      </Box>
    </PageWrapper>
  );
}
