import React, {createContext} from "react";
import {useClientPubId} from "src/control/hooks";
import {ErrorScreen} from "../components/ErrorScreen";
import {LoadingOverlay} from "../components/LoadingOverlay";
import {useQuery} from "react-query";
import {getDiscoveryData} from "./Query";

type providerProps = {
  shouldAlwaysShowChild?: boolean;
  children: React.ReactNode;
}

export const QueryContext = createContext<any>(null);

export const QueryProvider = ({
  shouldAlwaysShowChild = false,
  children
}: providerProps) => {
  const clientPubId = useClientPubId();
  const {
    data,
    isLoading,
    error
  } = useQuery<any, Error>(
    "discovery",
    () => getDiscoveryData(clientPubId),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false
    }
  );

  // const result = error ?
  //   <ErrorScreen message={error.message}/> :
  //   isLoading ?
  //     <LoadingOverlay/> : (
  //       <QueryContext.Provider value={data}>
  //         {props.children}
  //       </QueryContext.Provider>
  //     )

  // console.log({data}, {isLoading}, {error});
  if (shouldAlwaysShowChild) return (
      <QueryContext.Provider value={data}>
        {children}
      </QueryContext.Provider>
    )

  let result = <LoadingOverlay/>

  if (isLoading) {
    result = <LoadingOverlay/>
  }
  else if (error) {
    result = <ErrorScreen message={error.message}/>
  }
  else {
    result = (
      <QueryContext.Provider value={data}>
        {children}
      </QueryContext.Provider>
    )
  }

  return result;
}
