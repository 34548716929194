import {FC} from 'react';
import {styled} from "@mui/system";
import {Divider, Theme} from '@mui/material';
import {makeStyles} from '@mui/styles/';
import {NavLink} from 'react-router-dom';
import {pagePaths} from "../../routes";
import {useClientPubId} from "../hooks";

const useNavigationStyles = makeStyles<Theme>(
  ({ spacing, palette: {primary, text}}) => ({
    navigationItemLink: {
      position: 'relative',
      textDecoration: 'none',
      fontSize: 14,
      fontWeight: 700,
      color: primary.main,
      '&:hover': {
        color: text.secondary,
      },
    },
    navigationItemLinkActive: {
      color: text.secondary,
      cursor: 'default',
    },
  })
);

const StyledNavigtionContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  borderTop: '1px dotted lightgray',
  borderBottom: '1px dotted lightgray',
})

const StyledNavigtionMenu = styled('div')({
  display: 'flex',
  listStyle: 'none',
  marginTop: 0,
  marginBottom: 0,
  justifyContent: 'space-evenly',
  paddingTop: '1rem',
  paddingBottom: '1rem',
})

const NavigationDivider = () => {
  return (
    <Divider
      orientation="vertical"
      variant="inset"
      sx={{
        margin: '.2rem 10px', // so much fiddling
        height: '.9rem'
    }}
    />
  )
}

export const Navigation: FC = () => {
  const {
    navigationItemLink,
    navigationItemLinkActive,
  } = useNavigationStyles();

  const clientPubId = useClientPubId();

  const navLinkClassName = (options: any) =>
    `${navigationItemLink} ${options.isActive ? navigationItemLinkActive : ''}`

  // Subtle reason we're not mapping the menu items. Here are the facts:

  //   - Each map iteration return two components (for flexbox) - NavLink and divider,
  //   - React 17 still doesn't allow returning multiple components in a map,
  //   - Grouping the two components by anything but a React fragment <> </>
  //     makes them look like one component to flexbox
  //   - React spams the console with warnings unless each map item has a unique key
  //   - React doesn't support adding a key to a fragment (error: <key={blah}

  // There are other ways to format the Nav menu, but I can't like any of them
  // Not using map resulted in 5 extra lines below, but avoided 20 lines of array def

  return (
    <StyledNavigtionContainer>
      <StyledNavigtionMenu>
        <>
          <NavLink
            to={`${pagePaths.dashboard}/${clientPubId}`}
            className={navLinkClassName}
          >
            Dashboard
          </NavLink>
          <NavigationDivider />
          <NavLink
            to={`${pagePaths.applications}/${clientPubId}`}
            className={navLinkClassName}
          >
            Applications
          </NavLink>
          <NavigationDivider />
          <NavLink
            to={`${pagePaths.recommendations}/${clientPubId}`}
            className={navLinkClassName}
          >
            Recommendations
          </NavLink>
        </>
      </StyledNavigtionMenu>
    </StyledNavigtionContainer>
  );
};
