import {Box, Typography as T,} from '@mui/material';

import {PageWrapper} from 'src/control/PageWrapper/PageWrapper';
import {QueryProvider} from "../../api/QueryProvider";
import {useMutation, useQuery} from "react-query";
import {getDiscoveryData, request14DayTrial} from "../../api/Query";
import {useClientPubId} from "../../control/hooks";

import {DtxSpacer} from "../../components/DtxSpacer";
import {SpacedDivider, TextLink} from "./styles";

import {Savings} from "./Savings";
import {Risk} from "./Risk";
import {Usage} from "./Usage";
import {FreeTrialButton} from "./FreeTrialButton";
import {SavingsBlurb} from "./SavingsBlurb";
import * as React from "react";
import {useNavigate} from "react-router-dom";
import {pagePaths} from "../../routes";

export const use14DayTrialMutation = () => {
  const clientPubId = useClientPubId();
  const navigate = useNavigate();

  return useMutation(
    () => request14DayTrial(clientPubId),
    {
      onSuccess: (data: any) => {
        // console.log('onSuccess', {data});
        navigate(pagePaths.trialSuccess);
      },
      onError: (error: any) => {
        console.error('onError', {error});
        navigate(pagePaths.trialFailure);
      },
    }
  );
}

export const Recommendations = () => {
  const clientPubId = useClientPubId();
  const {
    data,
    // isLoading, error
  } = useQuery<any, Error>(
    "discovery",
    () => getDiscoveryData(clientPubId)
  );

  // data && console.log({data});

  const totalAppCount = data?.application_summaries?.length;

  // const totalAppUserCount = data?.summary.total_users;
  const uniqueUserCount = data?.summary.unique_users;

  const savingsLower = data?.summary.savings_lower
  const savingsUpper = data?.summary.savings_upper

  const savingsMin = data ?
    savingsLower.toLocaleString('en-US')
    : '';
  const savingsMax = data ?
    savingsUpper.toLocaleString('en-US')
    : '';

  const request14DayTrialMutation = use14DayTrialMutation();

  const handleSignupLinkClick = () => {
    request14DayTrialMutation.mutate();
  }

  return (
    <PageWrapper
      shouldShowNavigation={true}
      shouldShowToolWidget={true}
    >
      <QueryProvider>
        <DtxSpacer/>
        <T>We have found the following</T>
        <DtxSpacer space={5}/>
        <Savings savingsMin={savingsMin} savingsMax={savingsMax}/>
        <SpacedDivider width='80%'/>
        <Usage
          data={data}
          // totalAppUserCount={totalAppUserCount}
        />
    <DtxSpacer space={5} />
    <SavingsBlurb
      savingsUpper={savingsUpper}
      savingsLower={savingsLower}
      appCount={totalAppCount}
      uniqueUserCount={uniqueUserCount}
    />
    <DtxSpacer space={5} />
    <Box>
      <TextLink
        text="Activate the 14 Day Free Trial"
        onClick={handleSignupLinkClick}
        fontWeight="bold"
      /> to access full lists of app users and
      <br />
      verify cost savings opportunities.
    </Box>
        <SpacedDivider width='80%'/>
        <Risk data={data}/>
        <DtxSpacer space={1} />
        <CallToActionBlurb>
          Identify ghost users, SSO exceptions, apps<br />and users which you cannot deprovision
        </CallToActionBlurb>
        <DtxSpacer space={1}/>
        <FreeTrialButton />

      </QueryProvider>
    </PageWrapper>
   );
}

export const CallToActionBlurb = ({children}: any) => {
  return <>
    <SpacedDivider width='80%'/>
    <T sx={{
      fontWeight: 'bold',
      fontSize: '1.1rem',
    }}>
      {children}
    </T>
  </>
}
