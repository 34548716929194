import {styled} from "@mui/material";

export const BlurbWrapper = styled('div') (
  ({theme}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    border: 'none',
    ...theme.typography.body2,
    // paddingRight: '-10px',
    // marginTop: '-8px',
    textAlign: 'left',
    color: theme.palette.text.primary,
    // these will break the defunct Welcome Page
    width: '75%',
    margin: 'auto',
  })
);
