import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import {
  TableContainerDtx,
  TableCellDtx,
  TableHeaderCellDtx,
} from "src/pages/Dashboard/styles";

const appCountTableBackgroundColor = 'hsl(0, 0%, 98%)';

function AppCountTable(props: {aggregates: any, color: any}) {
  return (<TableContainerDtx
    sx={{
      backgroundColor: appCountTableBackgroundColor,
    }}
  >
    <Table
      aria-label="simple table"
    >
      <TableHead>
        <TableRow>
          <TableHeaderCellDtx omitLeftBorder={true}>{"Over 25 users"}</TableHeaderCellDtx>
          <TableHeaderCellDtx>{"10 to 25 users"}</TableHeaderCellDtx>
          <TableHeaderCellDtx>{"5 to 10 users"}</TableHeaderCellDtx>
          <TableHeaderCellDtx>{"Under 5 users"}</TableHeaderCellDtx>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCellDtx
            omitLeftBorder={true}
            color={props.color}
          >
            {props.aggregates?.over25_count}
          </TableCellDtx>
          <TableCellDtx
            color={props.color}
          >
            {props.aggregates?.tenTo25_count}
          </TableCellDtx>
          <TableCellDtx
            color={props.color}
          >
            {props.aggregates?.fiveTo10_count}
          </TableCellDtx>
          <TableCellDtx
            color={props.color}
          >
            {props.aggregates?.lessThan5_count}
          </TableCellDtx>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainerDtx>);
}

type props = {
  counts: any,
  color: any,
}

export const AppCounts = (props: props) => {
  return (
    <AppCountTable
      aggregates={props.counts}
      color={props.color}
    />
  );
}


