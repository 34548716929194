import React from 'react';
import ReactDOM from 'react-dom';
import './control/index.css';
import {App} from './control/App';


// Do we need all these? Do they add much to the bundle?
import "@fontsource/lato/latin-300.css";
import "@fontsource/lato/latin-400.css";
import "@fontsource/lato/latin-700.css";
import "@fontsource/lato/latin-300-italic.css";
import "@fontsource/lato/latin-400-italic.css";
import "@fontsource/lato/latin-700-italic.css";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app,
// pass a function to log results for example:
//    reportWebVitals(console.log))
// or send to an analytics endpoint.
// Learn more: https://bit.ly/CRA-vitals

// import reportWebVitals from './reportWebVitals';
// reportWebVitals();
