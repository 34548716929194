import {Typography as T, useTheme} from "@mui/material";
import budget1 from "../../assets/images/budget1.png";
import {DtxSpacer} from "../../components/DtxSpacer";
import * as React from "react";
import {StyledSectionLabel} from "./styles";

export const Savings = (
  props: {
    savingsMin: string,
    savingsMax: string
  }
) => {
  const theme = useTheme();
  return <>
    <img
      src={budget1}
      alt="Detexian"
      height="55px"
    />
    <StyledSectionLabel>Potential Cost Savings</StyledSectionLabel>
    <DtxSpacer space={1}/>
    <T
      sx={{
        fontSize: "2.5rem",
        color: theme.more.riskyRed
      }}
    >
      ${props.savingsMin} - ${props.savingsMax}
    </T>
  </>;
}
