import React, {useEffect} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import {pagePaths} from "../routes";

import {Dashboard} from 'src/pages/Dashboard/Dashboard';
import {Applications} from 'src/pages/Applications/Applications';
import {Recommendations} from 'src/pages/Recommendations/Recommendations';
import {Terms} from 'src/pages/Terms';
import {Privacy} from 'src/pages/Privacy';
import {ConfirmTrial} from "../pages/ConfirmTrial";
import {TrialSuccess} from "../pages/TrialSuccess";
import {TrialFailure} from "../pages/TrialFailure";


interface RedirectURLProps {
    url: string
}

const RedirectURL = ({url}: RedirectURLProps) => {
    useEffect(() => {
        window.location.href = url;
    }, [url]);

    return <></>;
};

export const Routing = () => {
  return (
      <Router>
        <Routes>
          <Route path={pagePaths.signup} element={<RedirectURL url={'https://www.detexian.com/discovery'} />} />
          {/*<Route path={pagePaths.welcome}>*/}
          {/*  <Route path=":clientPubId" element={<Overview  />} />*/}
          {/*  <Route path="" element={<Overview />} />*/}
          {/*</Route>*/}
          <Route path={pagePaths.welcome}>
            <Route path=":clientPubId" element={
                <Navigate to={pagePaths.dashboard} replace />
              }
            />
            <Route path="" element={
                <Navigate to={pagePaths.dashboard} replace />
              }
            />
          </Route>
          <Route path={pagePaths.dashboard}>
            <Route path=":clientPubId" element={<Dashboard  />} />
            <Route path="" element={<Dashboard />} />
          </Route>
          <Route path={pagePaths.applications}>
            <Route path=":clientPubId" element={<Applications />} />
            <Route path="" element={<Applications />} />
          </Route>
          <Route path={pagePaths.recommendations}>
            <Route path=":clientPubId" element={<Recommendations />} />
            <Route path="" element={<Recommendations />} />
          </Route>
          <Route path={pagePaths.terms} element={<Terms />} />
          <Route path={pagePaths.privacy} element={<Privacy />} />
          <Route path={pagePaths.trialConfirm} element={<ConfirmTrial />} />
          <Route path={pagePaths.trialSuccess} element={<TrialSuccess />} />
          <Route path={pagePaths.trialFailure} element={<TrialFailure />} />
       </Routes>
      </Router>
  );
}


