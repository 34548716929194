import {ApplicationAggregate} from "../../api/QueryTypes";

// The query response has two aggregate records
// one for non-IT-approved, one for IT-approved.
// This returns one or the other
export const aggregate = (
  aggregates: ApplicationAggregate[],
  enterprise: boolean
) => aggregates.find(
  (agg: ApplicationAggregate) => agg.is_enterprise === enterprise)

// this uses the above to return the non-IT-approved aggregate record
export const aggregateNonItApproved = (
  aggregates: ApplicationAggregate[]
) => aggregate(aggregates, false)

// this returns the IT-approved (enterprise) aggregate record
export const aggregateItApproved = (
  aggregates: ApplicationAggregate[]
) => aggregate(aggregates, true)


