import {Typography as T} from "@mui/material";
import * as React from "react";
import {RedCallout, RedText} from "./styles";

const annualSaaSCostPerUser = 2500; // estimate from Tan
const totalAppsThreshold = 270;
export const SavingsBlurb = ({
  savingsUpper,
  savingsLower,
  appCount,
  uniqueUserCount,
}: any) => {
  const industryAverageSaasSpend = uniqueUserCount * annualSaaSCostPerUser;
  const percentLower = Math.round(savingsLower*100/industryAverageSaasSpend);
  const percentUpper = Math.round(savingsUpper*100/industryAverageSaasSpend);
  return (
    <RedCallout>
      <T
        sx={{}}
        gutterBottom={false}
      >
        We estimate your total SaaS spend at ${industryAverageSaasSpend.toLocaleString('us-en')}*. Your Potential <br />Cost Savings is <RedText text={
          `${percentLower}-${percentUpper}%`}
        /> of your total SaaS spend or <RedText text={
          `$${savingsLower?.toLocaleString('us-en')}-$${savingsUpper?.toLocaleString('us-en')}`}
        />

        <br /> <br />
        Detexian discovered a total of <RedText text={appCount} /> apps. This is {appCount > totalAppsThreshold ? 'higher' : 'lower'} than your peer group.
        <br /> <br/>
        * <em style={{fontSize: '0.85rem'}}>we estimate your Saas spend by industry-average annual spend per user per app & your number of users & apps</em>
      </T>
    </RedCallout>
  )
}

// This is higher than the average of your peer group.
//           {/*at ${industryAverageSaasSpend.toLocaleString('us-en')} */}
