import {Box, Typography as T} from "@mui/material";
import * as React from "react";
import {RedCallout, RedText} from "./styles";
import {getAppsOfTypeEnterpriseOrUser} from "../Applications/Applications";
import {ApplicationSummary} from "../../api/QueryTypes";
import {nonITApprovedAppPhraseColor} from "./Risk";

export const RiskBlurb = ({
  data,
  nonItAppWithEmailAccessCount,
  nonItAppUsersWithoutSSO,
}: any) => {
  const avgNonItPercent = 56;
  // const avgItAppsSsoPercent = 50;

  const appSummaries = data?.application_summaries
  const appCount = appSummaries.length

  const nonItApps = getAppsOfTypeEnterpriseOrUser(
    appSummaries,
    false // specify Non-IT-Approved apps
  )

  const nonItCount = nonItApps?.length

  const nonItPercent = Math.round(100 * nonItCount / appCount);
  const nonItExceedsAvg = nonItPercent > avgNonItPercent;

  const nonItAppEmailAccessUserCount = nonItApps.reduce(
    (acc: any, app: ApplicationSummary) =>
      acc + (app.email_access ? app.user_count : 0)
    , 0 // init the accumulator to zero - breaks otherwise (why?)
  )

  const NonItApprovedApp = ({count}: {count?: number}) => {
    return <Box component='span' sx={{color: nonITApprovedAppPhraseColor}}>
      Non-IT-Approved {`App${count === 1 ? '' : 's'}`}
    </Box>
  }

  return <RedCallout>
    <T
      sx={{

      }}
      gutterBottom={false}
    >
     <NonItApprovedApp /> are <RedText text={`${nonItPercent}%`} /> of the total apps discovered.<br /> This is {nonItExceedsAvg ? 'higher' : 'lower'} than the industry average.

      <br /><br />

      <RedText
        text={`${nonItAppWithEmailAccessCount}`} /> <NonItApprovedApp
        count={nonItAppWithEmailAccessCount}/> {
          `${nonItAppWithEmailAccessCount === 1 ? ' has' : 'have'}`
      } <RedText text="access to" /> one or more staff <RedText
        text="mailboxes" />. <br />Summing user access, you have <RedText
        text={`${nonItAppEmailAccessUserCount} risks `}
      /> of <em>Business Email Compromise</em>.

      <br /><br />

      <RedText text={
        `${nonItAppUsersWithoutSSO?.toLocaleString('us-en')} app users`
      }/> of <NonItApprovedApp /> <em>cannot be deprovisioned<br /> by IT</em> even after they have left the organization.
    </T>
  </RedCallout>;
}

      // {/*Your IT team has no control over {nonItPercent}% of your apps. <br />*/}
      // {/*This is {nonItExceedsAvg ? 'higher' : 'lower'} than the industry average.*/}
      //    {/*<br />via Non-IT Approved Apps.*/}
      //      {/*50% of your IT-Approved-App users don't use SSO. <br />*/}
      // {/*This is higher than the industry average.*/}
      // {/*Users without SSO are likely to be overlooked when offboarded. <br />*/}
      // {/*They may still have access to corporate data.*/}
      // {/*<br /> <br />*/}
// There {nonItAppWithEmailAccessCount === 1 ? 'is' : 'are'}
// Andy's original version
/*
      Non-IT Approved Apps are 90% of the total apps discovered. This is HIGHER than the industry average.
      <br />
      App Users without SSO in IT-Approved Apps are 50% of the total App Users. This is HIGHER than the industry average.
      <br />
      Users without SSO are likely to be missed when offboarded. They may still have access to corporate data.
      <br />
      There are 9484 App Users in Non-IT-Approved Apps that cannot be deprovisioned by IT even after they have left the organization.

 */
