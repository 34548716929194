import {DataGrid} from "@mui/x-data-grid";
import {styled} from "@mui/material/styles";
import {Theme} from "@mui/material";
import {
  dataGridRowBorder,
  tableBorderLine, tableHeaderBottomBorder
} from "../../control/theme";

export const magicHeaderHeightHack = '56px'; // fragile, but "100%" no worky

// TODO: find the best way to put this in the theme
// this allows for dark mode, but should centralize it
const borderStyle = (theme: Theme) =>
  `${tableBorderLine} ${
    theme.palette.mode === 'light' ? 
    theme.more.tableOutsideBorderColor : 
    '#303030'
  }`

// const borderRowStyle = (theme: Theme) =>
//   `${tableBorderLine} ${theme.more.tableRowBorderColor}`

export const StyledDataGrid = styled(DataGrid)(({theme}) => ({
    // Note: styling MuiDataGrid-root here doesn't seem
    // to work.  Instead, use e.g. sx={{border: 'none'}}

    // The below produces an unwanted border radius above the
    // pagination footer...

    // Worked around it by removing border on MuiDataGrid-root:
    //   sx={{border: 'none'}}

    // Otherwise we'd need a conditional border radius.

    'div.MuiDataGrid-main': {
      border: borderStyle(theme),
      borderRadius: theme.more.borderRadius,
    },

    // Gets rid of the little Column Header separators
    '.MuiDataGrid-iconSeparator': {
      display: 'none',
    },

    // Allows the Column Headers and the cells (re the
    // Action column) cells to wrap
    '.MuiDataGrid-cell': {
      textOverflow: "clip",
      whiteSpace: "break-spaces",
      lineHeight: 1.1, // any tighter truncates descenders
    },

    // NOTE: this hard left-aligns the Applications column.
    // Fragile and why do I have to do it?  Geez.
    // Why is there no other way to left align this column?
    '.MuiDataGrid-cell:first-of-type': {
      textAlign: 'left',
    },

    '.MuiDataGrid-columnHeaderTitle': {
      whiteSpace: "break-spaces",
      lineHeight: 1,
      fontSize: '0.8rem',
      fontWeight: 700,
    },

    'div > .MuiDataGrid-columnHeader': {
      minHeight: magicHeaderHeightHack, // fragile, but "100%" doesn't work
      borderBottom: tableHeaderBottomBorder,
    },

    // Oddly, this has to be here or the second table, Enterprise Apps
    // doesn't have a border below the header.  Even odder, it doesn't
    // matter how you style the borderBottom, it just has to be there.
    // e.g. you can make the color 'red' (it won't be red)
    '.MuiDataGrid-columnHeadersInner': {
      borderBottom: tableHeaderBottomBorder,
    },

    // allow as much space as possible for the headings
    // but skip the left-justified Application heading
    'div.MuiDataGrid-columnHeader:not(:first-of-type)': {
      paddingLeft: '3px',
      paddingRight: '3px',
    },

    // I now have a '\n` between the words so don't need this
    // '.MuiDataGrid-columnHeader--alignCenter': {
    // // kludge so all the heading titles wrap
    //   paddingLeft: '3%',
    //   paddingRight: '3%',
    // },

    // Border Right
    // Needs the extra specificity of the nested selectors
    // or the last row's cells don't get their right border.
    // i.e. it doesn't work without .MuiDataGrid-row. But why?
    '.MuiDataGrid-row .MuiDataGrid-cell:not(:last-child), .MuiDataGrid-columnHeader:not(:last-child)': {
      borderRight: borderStyle(theme),
    },
    // Row border bottom
    // Thank you Stack Overflow - this seemed impossible
    // This version much simplified from the SO answer
    'div > .MuiDataGrid-cell': {
      borderBottom: dataGridRowBorder,
    }
  })
);
