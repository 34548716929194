import {NavigateFunction} from "react-router-dom";
import ReactGA from "react-ga4";
import {pagePaths} from "../../routes";

export const dev_goToNextPage = (
  pathname: string,
  navigate: NavigateFunction,
  clientPubId: string
) => {
  if (
    // !process.env.NODE_ENV ||
    // process.env.NODE_ENV === 'development' ||
    process.env.REACT_APP_ALLOW_DEV_NAVIGATION
  ) {
    ReactGA.event({
      category: "PageWrapper",
      action: "Secret Developer Navigation",
      label: window.location.href,
    });
    if (pathname === pagePaths.signup)
      navigate(pagePaths.agree);
    else if (pathname.includes(pagePaths.agree))
      navigate(pagePaths.onboardSuccess);
    else if (pathname.includes(pagePaths.onboardSuccess))
      navigate(`${pagePaths.onboardError}?error=could%20not%20fetch%20authorization%20token`);
    else if (pathname.includes(pagePaths.onboardError))
      navigate(`${pagePaths.welcome}/${clientPubId}`);
    else if (pathname.includes(pagePaths.welcome))
      navigate(`${pagePaths.dashboard}/${clientPubId}`);
    else if (pathname.includes(pagePaths.dashboard))
      navigate(`${pagePaths.applications}/${clientPubId}`);
    else if (pathname.includes(pagePaths.applications))
      navigate(`${pagePaths.recommendations}/${clientPubId}`);
    else if (pathname.includes(pagePaths.recommendations))
      navigate(`${pagePaths.trialConfirm}`);
    else if (pathname.includes(pagePaths.trialConfirm))
      navigate(pagePaths.trialSuccess);
    else if (pathname.includes(pagePaths.trialSuccess))
      navigate(pagePaths.trialFailure);
    else if (pathname.includes(pagePaths.trialFailure))
      navigate(pagePaths.signup);
    else navigate(pagePaths.signup);
  }
}
