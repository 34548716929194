import {createTheme} from "@mui/material/styles";
import '@fontsource/lato';
// import darkScrollbar from "@mui/material/darkScrollbar";

export const andyBlue = '#4068B1';
export const borderRadius = '13px';

export const riskyHue = '6';
export const riskySaturation = 89;

const riskyRed = `hsl(${riskyHue}, ${riskySaturation}%, 54%)`; // '#f23620';
export const riskyBulletColor = `hsl(${riskyHue}, ${riskySaturation}%, 70%)`;
// no longer used
export const darkRiskyRed = `hsl(${riskyHue}, ${riskySaturation}%, 21%)`;

const andyGreen = '#399541';
const sjsOrange = 'hsl(28, 100%, 50%)';
const submitButtonColor = '#02aadc'

export const pageContentWidth = '700px';
export const headerHeight = '75px';
export const hoverOpacity = '60%';

// So much table border stuff
export const generalBorderLine = '1px solid';
export const tableBorderLine = generalBorderLine;

const generalBorderColor = 'lightgray';
export const generalBorder = `${tableBorderLine} ${generalBorderColor} `

const tableOutsideBorderColor = generalBorderColor;
export const tableColumnBorderColor = tableOutsideBorderColor;
const tableHeaderBottomBorderColor = tableOutsideBorderColor;
const tableRowBorderColor = 'hsl(0, 0%, 80%)';
export const dataGridRowBorderColor = 'hsl(0, 0%, 90%)';

export const dataGridRowBorder =
  `${tableBorderLine} ${dataGridRowBorderColor}`
export const tableHeaderBottomBorder =
  `${tableBorderLine} ${tableHeaderBottomBorderColor}`
export const tableRowBorder =
  `${tableBorderLine} ${tableRowBorderColor}`;

export const linkStyleFragment = {
  textDecoration: "none",
  color: andyBlue,
    ':hover': {
    opacity: hoverOpacity,
  }
}

// Adding a "more" field to overcome the crushing limitations
// of the theme in a TypeScript environment
declare module '@mui/material/styles' {
  interface Theme {
    more: any;
  }
  interface ThemeOptions {
    more: any;
  }
}

export const h2FontSize = '2.3rem';

export const theme = createTheme({
  typography: {
    fontFamily: '"Lato", sans-serif',
    // why does TypeScript allow headings but nothing else?
    h1: {
      fontSize: '2.8rem',
      fontWeight: 'bold'
    },
    h2: {
      fontSize: h2FontSize,
      fontWeight: 'normal'
    },
    h3: {
      fontSize: '1.8rem',
      fontWeight: 'bold',
      color: 'gray'
    },
    h4: {
      fontSize: '1.4rem',
      fontWeight: 'bold',
      color: 'gray'
    },
    h5: {
      fontSize: '1.3rem',
      fontWeight: 'normal',
      color: 'gray'
    },
    h6: {
      fontSize: '1.0rem',
      fontWeight: 'bold',
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          color: 'black',
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '8px', // doesn't seem to do much - double check
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: andyBlue,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          // border: tableRowBorder, //// makes the outside border thick
          // borderCollapse: "unset"
        },
      },
    }
    // These don't change the table text color
    // MuiTable: {
    //   styleOverrides: {
    //     root: {
    //       color: 'black',
    //     },
    //   },
    // },
    // These don't change the table text color
    // MuiTableRow: {
    //   styleOverrides: {
    //     root: {
    //       color: 'black',
    //     },
    //   },
    // },
    // MuiTableCell: {
    //   styleOverrides: {
    //     root: {
    //       color: 'black',
    //     },
    //   },
    // },
 },

  palette: {
    common: {black: '#000', white: '#fff'},
    /*
    primary: {
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    */
    primary: {main: '#3D68B2', dark: '#1B75BC'},
    /*
    secondary: {
      // light: '#0066ff',
      main: 'steelblue',// '#0044ff',
      // dark: will be calculated from palette.secondary.main,
      // contrastText: '#ffcc00',
    },
    */
    secondary: {main: '#7CC957', dark: '#009900'},
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    // contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    // tonalOffset: 0.2,

    // from Portal V2 - probably not how we want to go
    // better to use light: and dark: - I think
    text: {primary: '#000', secondary: '#999999'},
    background: {default: 'rgb(241, 241, 241)'},
    error: {main: '#FF0000'},
    warning: {main: '#FF9900'},
    success: {main: '#7CC957'},
    divider: 'rgba(221, 221, 221, 1)',
    // tableHeader: 'rgba(170, 170, 170, 1)',
  },
  more: {
    tableOutsideBorderColor,
    tableColumnBorderColor,
    tableRowBorderColor,
    borderRadius,
    andyBlue,
    riskyRed,
    andyGreen,
    sjsOrange,
    submitButtonColor,
    blueGradientTop: 'linear-gradient' +
    '(' +
      '180deg, ' +
      'rgba(66,168,210,0.26934523809523814) 0%, ' +
      'rgba(66,168,210,0) 40%, ' +
      'rgba(66,168,210,0) 100% ' +
    ')',
    hoverOpacity,
    headerHeight,
    smallFontSize: '0.8rem',
  }
});


