import {Button, useTheme} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {pagePaths} from "../../routes";
// import {use14DayTrialMutation} from "./Recommendations";

export const FreeTrialButton = () => {

  // const request14DayTrialMutation = use14DayTrialMutation();

  // const handle14DayTrialRequest = () => {
  //   request14DayTrialMutation.mutate();
  // }


  const navigate = useNavigate();

  const theme = useTheme();
  return <Button
    variant="contained"
    sx={{
      maxHeight: "35px",
      marginTop: "8px",
      color: "white",
      backgroundColor: theme.more.andyBlue,
    }}
    onClick={() => navigate(pagePaths.trialConfirm)}
    type="submit"
  >
    Sign up for 14-Day Free Trial
  </Button>;
}
