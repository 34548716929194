import {Link, Theme, Typography} from "@mui/material";
import * as React from "react";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import {linkStyleFragment} from "src/control/theme";
import {HtmlTooltip} from "../../components/HtmlTooltip";

export const userCountFieldName = 'user_count';


const ToolTipLink = () => {
  return <Link
    href='http://calendly.com/andy-detexian'
    target="_blank"
    sx={{
      fontSize: '0.9rem',
      ...linkStyleFragment
    }}
  >
    book a free 5 min consult
  </Link>;
}

const sharedColumnSettings: any = {
  width: 80,
  editable: false,
  headerAlign: 'center',
  align: 'center',
}

const displayValueFromBool = (bool: boolean) =>
  bool ? 'Yes' : '';

const accessRenderCell = (access: boolean, theme: Theme) => {
  return (
    access ?
      <HtmlTooltip
        title={
          <React.Fragment>
            <span
              style={{
                fontSize: '0.9rem'
              }}
            > For full scopes </span>
            <ToolTipLink />
          </React.Fragment>
        }
      >
        <Typography
          variant='body2'
          sx={{
            color: access ? theme.more.riskyRed : theme.more.andyGreen
          }}
        >
          {displayValueFromBool(access)}
        </Typography>
      </HtmlTooltip>
    :
      <Typography
        variant='body2'
        sx={{
          color: access ? theme.more.riskyRed : theme.more.andyGreen
        }}
      >
        {displayValueFromBool(access)}
      </Typography>

  );
}

const actionRenderCell = (action: string, theme: Theme) => {
  return (
    <Typography
      variant='body2'
      sx={{
        color: theme.more.sjsOrange,
        lineHeight: '0.9rem',
      }}
    >
      {action}
    </Typography>
  );
}

const countRenderCell = (app: any, theme: Theme) => {
  return (
    <HtmlTooltip
      title={
        <React.Fragment>
          <span
            style={{
              fontSize: '0.9rem'
            }}
          >For the full user list </span>
          <ToolTipLink />
        </React.Fragment>
      }
    >
      <Typography
        variant='body2'
        // sx={{
        //   lineHeight: '0.9rem',
        // }}
      >
        {app[userCountFieldName]}
      </Typography>
    </HtmlTooltip>
  );
}

const statusColumn = (fieldName: string, headerName: string, theme: Theme) => {
  return {
    field: fieldName,
    headerName: headerName,
    ...sharedColumnSettings,
    renderCell: (params: GridRenderCellParams) =>
      accessRenderCell(params.row[fieldName], theme)
  }
}

export const buildUserConsentedColumns = (theme: Theme): GridColDef [] => {
  const columns: GridColDef [] = [
    {
      field: 'application_name',
      headerName: 'App',
      width: 175,
      editable: false,
      // None of these left align.
      // Ended up having to do it, dodgily, in the styled component.
      // textAlign: 'left',
      // headerAlign: 'left',
      // alignItems: 'left',
      // align: 'left',
    },
    {
      field: userCountFieldName,
      headerName: 'User\nCount',
      ...sharedColumnSettings,
      renderCell: (params: GridRenderCellParams) =>
        countRenderCell(params.row, theme)
    },
    statusColumn('write_access', 'Write\nAccess', theme),
    statusColumn('email_access', 'Email\nAccess', theme),
    statusColumn('calendar_access', 'Calendar\nAccess', theme),
    statusColumn('file_access', 'File\nAccess', theme),
    {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'center',
      width: 125,
      renderCell: (params: GridRenderCellParams) =>
        actionRenderCell(params.row['action'], theme)
    },
  ];
  return columns;
}

export const buildEnterpriseColumns = (theme: Theme): GridColDef [] => {
  const columns: GridColDef [] = [
    {
      field: 'application_name',
      headerName: 'App',
      width: 350, //175,
      editable: false,
      // align: 'left',
    },
    {
      field: userCountFieldName,
      headerName: 'User Count',
      width: 350,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: GridRenderCellParams) =>
        countRenderCell(params.row, theme)
    },
  ];
  return columns;
}

export const actionColumnIntialValue = '';

export const addReviewItem = (
  existingText: string,
  newText: string
) => {
  let result = existingText;
  if (result !== actionColumnIntialValue) {
    result += ', '
  } // comma between items
  result += newText;
  return result;
}
