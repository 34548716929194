import usage1 from "../../assets/images/usage1.png";
import {DtxSpacer} from "../../components/DtxSpacer";
import {Box, styled, Typography as T} from "@mui/material";
import * as React from "react";
import {
  StyledSectionLabel,
  StyledUsageLabel,
  StyledUsageValue
} from "./styles";

const SingleSampleLabel = styled(T)(({theme}) => ({
  fontWeight: 'bold',
  fontSize: '0.9rem',
}));

const SingleSampleClarifier = styled(T)(({theme}) => ({
  fontStyle: 'italic',
  fontSize: '0.9rem',
  color: theme.palette.text.secondary,
}));

const sampleWidth = 150;

type SingleSampleValueProps = {shouldAllowOverflow?: boolean};

const SingleSampleValue = styled(
  T,
  // prevent React DOM element attribute warning
  {shouldForwardProp: (prop) => prop !== "shouldAllowOverflow"}
) <SingleSampleValueProps>(
  ({theme, shouldAllowOverflow}) => ({
    fontWeight: 'italic',
    fontSize: '0.85rem',
    color: theme.more.riskyRed,
    overflow: shouldAllowOverflow ? 'visible' : 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    // allow some extra width for large third - column field
    maxWidth: `${sampleWidth + (shouldAllowOverflow ? 55 : -8)}px`,
    margin: 'auto',
  })
);

const SingleObscuredSample = (
  {transparency = 85}: // 0 to 100 - 100 -> invisible
  {transparency?: number }
) => {
  return <Box
    sx={{
      textAlign: 'center',
      margin: 'auto',
      height: '17px',
      width: `${sampleWidth}px`,
      backgroundColor: `hsl(0, 0%, ${transparency}%)`, // 'gray.300',
      borderRadius: '8px',
    }}
  >
  </Box>;
}

const ObscuredSampleData = () => {
  const spacing = 0.9
  return (<>
    <SingleObscuredSample transparency={80} />
    <DtxSpacer space={spacing} />
    <SingleObscuredSample transparency={87} />
    <DtxSpacer space={spacing} />
    <SingleObscuredSample transparency={94} />
  </>)
}

const UsageColumn = ({
  usageColumnName,
  count,
  singleSampleLabel,
  singleSampleValue,
  shouldAllowOverflow, // has to be lower case to shut React up.  Why?
}: any) => {
  return <Box sx={{width:'30%'}}>
    <StyledUsageLabel>{usageColumnName}</StyledUsageLabel>
    <DtxSpacer space={0.8}/>
    <StyledUsageValue>
      {count}
    </StyledUsageValue>
    <DtxSpacer space={1.5}/>
    <SingleSampleLabel>{singleSampleLabel}</SingleSampleLabel>
    <DtxSpacer space={0.3}/>
    <SingleSampleClarifier>
      (1 of {count})
    </SingleSampleClarifier>
    <DtxSpacer space={0.6}/>
    <SingleSampleValue shouldAllowOverflow={shouldAllowOverflow}>
      {singleSampleValue}
    </SingleSampleValue>
    <DtxSpacer space={0.5}/>
    <ObscuredSampleData/>
  </Box>;
}

export const Usage = ({
  data,
}: {
  data: any,
}) => {
  // data && console.log({data});
  const appSummaries = data?.application_summaries;
  const sampleApplicationName = appSummaries?.[0]?.application_name
  const summary = data?.summary;
  const examples = data?.clientExamples;
  return <>
    <img
      src={usage1}
      alt="Detexian"
      height="55px"
    />
    <StyledSectionLabel>Usage</StyledSectionLabel>
    <DtxSpacer/>
    <Box
      sx={{
        display: "flex",
        margin: "auto",
        justifyContent: "center",
      }}
    >
      <UsageColumn
        usageColumnName="No of Apps"
        count={appSummaries?.length}
        singleSampleLabel="App Name"
        singleSampleValue={sampleApplicationName}
      />
      <DtxSpacer orientation="vertical" space={8}/>
      <UsageColumn
        usageColumnName="No of Unique Users"
        count={summary.unique_users}
        singleSampleLabel="User Name"
        singleSampleValue={examples.user}
      />
      <DtxSpacer orientation="vertical" space={8}/>
      <UsageColumn
        usageColumnName="No of App Users"
        count={summary.total_users}
        singleSampleLabel="User & App"
        singleSampleValue={`${examples.app_user_name} - ${examples.app_user_app}`}
        shouldAllowOverflow={true}
      />
    </Box>
  </>;
}


      // Duplicate Apps
      // <Box>
      //   <StyledUsageLabel>Duplicate Apps</StyledUsageLabel>
      //   <StyledUsageValue>
      //     {props.data?.duplicatedAppCount} Apps
      //   </StyledUsageValue>
      // </Box>
      // <DtxSpacer orientation="vertical" space={8}/>
