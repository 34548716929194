import React, {useContext, useState} from 'react';
import {PageWrapper} from 'src/control/PageWrapper/PageWrapper';
import Typography from '@mui/material/Typography';
import {Button, Checkbox, FormControlLabel, useTheme} from "@mui/material";
import Check from '@mui/icons-material/Check';
import {BoxDetexian} from "../components/BoxDetexian";
import {BlurbWrapper} from "../components/BlurbWrapper";
import {AnyQuestionsOrHelp} from "./Signup/Signup";
import {use14DayTrialMutation} from "./Recommendations/Recommendations";
import {tableColumnBorderColor} from "../control/theme";
import {DtxSpacer} from "../components/DtxSpacer";
import {QueryContext, QueryProvider} from "../api/QueryProvider";
import {Privacy, Terms} from "../control/PageWrapper/FinePrint";


const ConfirmTrialComponent = () => {
  const theme = useTheme();
  const spacing = theme.spacing(5);
  const queryData = useContext(QueryContext);
  // console.log({queryData});
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);

  const request14DayTrialMutation = use14DayTrialMutation();

  // do nothing until agreed to terms
  const handle14DayTrialRequest = hasAgreedToTerms ?
    () => request14DayTrialMutation.mutate() :
    () => {};
  return <>
    <BoxDetexian
      width="85%"
      paddingBottom={spacing}
    >
      <Check
        sx={{
          color: theme.more.andyGreen,
          fontSize: "2.5rem",
          fontWeight: "bold",
        }}
      />
      <Typography
        variant="h3"
        sx={{
          marginBottom: spacing,
        }}
      >
        14-Day Free Trial
      </Typography>
      <BlurbWrapper sx={{
        margin: "auto",
        width: "70%"
      }}>
        <Typography
          variant="body1"
          gutterBottom
        >
          Your email for this trial: {queryData?.name}
          <br/>
          <br/>
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
        >
          Our system will email you a password reset request. Please follow the prompt to set your own password.
        </Typography>
        <DtxSpacer/>
        <FormControlLabel
          label={
          <span>
            Agree with Detexian <Terms>Terms</Terms> and <Privacy>Privacy Policy</Privacy>
          </span>}
          control={<Checkbox
            name={"agree-to-terms"}
            checked={!!hasAgreedToTerms}
            onChange={() => setHasAgreedToTerms(!hasAgreedToTerms)}
            // inputProps={{'aria-label': queryKey}}
          />}
        />
        <DtxSpacer/>
        <Button
          variant="contained"
          size="large"
          disabled={!hasAgreedToTerms}
          sx={{
            // fontSize: '1.1 rem',
            // maxHeight: "35px",
            // marginTop: "8px"
            marginLeft: "144px",
            // marginRight: 'auto',
            color: "white",
            backgroundColor: hasAgreedToTerms ?
              theme.more.andyBlue : tableColumnBorderColor
          }}
          onClick={handle14DayTrialRequest}
          type="submit"
        >
          Activate Now
        </Button>
      </BlurbWrapper>
    </BoxDetexian>
    <br/>
    <br/>
    <AnyQuestionsOrHelp/>
  </>;
}

export const ConfirmTrial = () => {

  return (
    <PageWrapper>
      <QueryProvider>
        <ConfirmTrialComponent />
      </QueryProvider>
    </PageWrapper>
   );
}
