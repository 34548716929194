import {Box, Typography, useTheme} from "@mui/material";
import ReactGA from "react-ga4";
import React, {useContext} from "react";
// import {useScreenshot, createFileName} from 'use-react-screenshot'

// import {useClientPubId} from "../hooks";
// import {useQuery} from "react-query";
// import {getDiscoveryData} from "../../api/Query";
import {andyBlue} from "../theme";
import {grey} from '@mui/material/colors';
import {QueryContext} from "../../api/QueryProvider";

const ToolLink = ({title, onClick}: any) => {
  return <Typography sx={{
    color: andyBlue,
    padding: 0,
    margin: 0,
    fontSize: "0.9rem"
  }}
  >
    <span
      onClick={onClick}
      style={{cursor: 'pointer'}}
    >
      {title}
    </span>
  </Typography>;
}

type widgetprops = {
  visibility?: any;
}

export const ToolsWidget = ({
  visibility,
}: widgetprops) => {
  // TODO replace all these with a context like
  // https://codesandbox.io/s/react-query-app-state-2-4he1q

  // const clientPubId = useClientPubId();
  // const {pathname} = useLocation();
  const queryData = useContext(QueryContext);

  // const {data} = useQuery<any, Error>(
  //   "discovery",
  //   () => getDiscoveryData(clientPubId)
  // );

  const theme = useTheme();

  // const [image, takeScreenShot] = useScreenshot();
  // const getImage = () => takeScreenShot(window.PAGE_REF.current);
  // const download = (image: string, {name = 'img', extension = 'png'} = {}) => {
  //   const a = document.createElement('a');
  //   a.href = image;
  //   a.download = createFileName(extension, name);
  //   a.click();
  // };

  // useEffect(() => {
  //   if (image) {
  //     download(image, {name: pathname, extension: 'png'});
  //   }
  // }, [image, pathname]);

  const sendEmail = (e: any) => {
    ReactGA.event("share", {
      label: window.location.href,
      debug_mode: true,
    });
    // build the mailto link
    const fullUrl = window.location.href;
    const subject = 'Detetxian%20Discovery%20site'
    const aMailto = `mailto:?subject=${subject}&body=${fullUrl}`;
    e.preventDefault();
    // send the email
    window.location.href = aMailto;
  };

  // takes into consideration the prop and data availability
  const calculatedVisibility = !queryData ? 'hidden' : visibility;

  return (
    // visibility !== 'hidden' ? (
    <>
      <Box style={{
        // left one is invisible for balancing the header content,
        // centering the logo.  See <Header /> component
        visibility: calculatedVisibility,
        display: 'grid',
        gridAutoFlow: 'row',
        gap: theme.spacing(0),
      }}>
        <Typography sx={{
          color: grey[900],
          padding: 0,
          margin: 0,
          fontSize: '0.9rem'
        }}>
          {queryData?.name}
        </Typography>

        <Box style={{
          visibility: calculatedVisibility,
          display: 'grid',
          gridAutoFlow: 'column',
          justifyContent: 'flex-end',
          alignContent: 'flex-end',
          gap: theme.spacing(1),
          paddingRight: 0,
          // marginRight: 0,
        }}>
          {/*<ToolLink title="Download" onClick={getImage}/>*/}
          {/*<Divider orientation="vertical" sx={{height:'0.8rem', marginTop: '0.3rem'}}/>*/}
          <ToolLink title="Share" onClick={sendEmail}/>
        </Box>
      </Box>
    </>
    // ) : <Screenshot pageRef={pageRef}/>
  )
};



// maybe useful for troubleshooting download styling
// type props = {pageRef: any};
// const Screenshot = ({pageRef}: props) => {
//   const [image, takeScreenShot] = useScreenshot()
//   const [width, setWidth] = useState(300)
//
//   const getImage = () => takeScreenShot(pageRef.current)
//
//   const download = (image: string, { name = 'img', extension = 'png' } = {}) => {
//     const a = document.createElement('a')
//     a.href = image
//     a.download = createFileName(extension, name)
//     a.click()
//   }
//
//   useEffect(() => {
//     if (image) {
//       download(image, { name: 'lorem-ipsum', extension: 'png' })
//     }
//   }, [image])
//
//   return (
//     <div>
//       <div>
//         <Button style={{ marginTop: '800px' }} onClick={getImage}>
//           Take screenshot
//         </Button>
//         <label style={{ display: 'block', margin: '10px 0' }}>
//           Width:
//           <input
//             value={width}
//             onChange={
//             // @ts-ignore
//               e => setWidth(e.target.value)
//             }
//           />
//         </label>
//       </div>
//       <img width={width} src={image} alt={'ScreenShot'} />
//     </div>
//   )
// }
